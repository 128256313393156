import { useState, useRef, useEffect } from "react";
import LinearActivityBar from "../../../components/ProgressBar/LinearActivityBar";
import moment from "moment";
import {
  ShowNotesModal,
  AddNoteModal,
  DeleteScreenRecordingModal,
} from "./ScreenRecordingsModals";

import {
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  SingleCardContainer,
  CardUpperText,
  NoteBadge,
  NoteText,
  TimeMonitorSection,
  ActivityProductiveSection,
  NoteDeleteSection,
  NoSceenshotTooltip,
  RoundQues,
  ToolTipContainer,
  ToolTip,
  ToolTipTriangle,
  CollapseText,
  CollapseQues,
  CollapseRotate,
} from "../../../styledComponents/Activity";
import { CommonText } from "../../../styledComponents/common";
import VideoItem from "./VideoItem";

import CommonTooltip from "../../../components/Tooltip/CommonTooltip";

import monitor from "../../../assets/img/icons/monitor.svg";
import Collapse from "../../../assets/img/icons/collapse.svg";
import CheckBox from "../../../components/CheckBox/checkBox";

const InfoTooltip = (props) => {
  const [active, setActive] = useState(false);
  const [collapse, setCollpase] = useState(1);
  const wrapperRef1 = useRef(null);
  const wrapperRef2 = useRef(null);

  //function
  useOutsideClickHandler(wrapperRef1, wrapperRef2);

  function useOutsideClickHandler(ref1, ref2) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref1.current &&
          !ref1.current.contains(event.target) &&
          ref2.current &&
          !ref2.current.contains(event.target)
        ) {
          setActive(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref1, ref2]);
  }

  const toggleCollapse = (value) => {
    setCollpase(value);
  };
  return (
    <NoSceenshotTooltip padding={props.padding} marginTop={props.marginTop}>
      <CommonText margin="auto 0 18px 0" fontSize="12px">
        Why is this was missed?
      </CommonText>
      <RoundQues onClick={() => setActive(true)} ref={wrapperRef1}>
        ?
        {active && (
          <ToolTipContainer ref={wrapperRef2}>
            <ToolTip>
              <ToolTipTriangle />

              <CommonText title name margin="0 0 5px 20px">
                The shot can be missed because
              </CommonText>
              <CollapseQues onClick={() => toggleCollapse(1)}>
                <CommonText title name fontSize="13px">
                  The screenshot feature is turned off in the settings.
                </CommonText>
                <CollapseRotate collapse={collapse} index={1}>
                  <img src={Collapse} alt="collapse" width="100%" />
                </CollapseRotate>
              </CollapseQues>
              <CollapseText fontSize="13px" collapse={collapse} index={1}>
                The screenshot feature can be turned off from the activity
                settings. The owner/admin can either turn it off for the whole
                organization or for an individual member. So, if it is turned
                off then you will not get any screenshot.
              </CollapseText>

              <CollapseQues onClick={() => toggleCollapse(2)}>
                <CommonText title name fontSize="13px">
                  If the user stops the desktop app in between a 10 minutes
                  frame.
                </CommonText>
                <CollapseRotate collapse={collapse} index={2}>
                  <img src={Collapse} alt="collapse" width="100%" />
                </CollapseRotate>
              </CollapseQues>
              <CollapseText fontSize="13px" collapse={collapse} index={2}>
                Apploye captures random screenshots every 10 minutes. So, if the
                user stopped the app within a 10 minute frame when the
                screenshot was not yet captured, then you will not get the
                screenshot for that time frame.
              </CollapseText>

              <CollapseQues onClick={() => toggleCollapse(3)}>
                <CommonText title name fontSize="13px">
                  If screenshot capture permission is not given in Mac.
                </CommonText>
                <CollapseRotate collapse={collapse} index={3}>
                  <img src={Collapse} alt="collapse" width="100%" />
                </CollapseRotate>
              </CollapseQues>
              <CollapseText fontSize="13px" collapse={collapse} index={3}>
                If the user is using a Mac, then he/she needs to give a
                permission to capture screenshots. Apploye automatically asks
                for the permission after install. If the user has denied the
                permission then the screenshot will not be taken.
              </CollapseText>

              <CollapseQues onClick={() => toggleCollapse(4)}>
                <CommonText title name fontSize="13px">
                  If the user is tracking from mobile.
                </CommonText>
                <CollapseRotate collapse={collapse} index={4}>
                  <img src={Collapse} alt="collapse" width="100%" />
                </CollapseRotate>
              </CollapseQues>
              <CollapseText fontSize="13px" collapse={collapse} index={4}>
                If the user is tracking time from Apploye mobile app, then it
                will not take any screenshot.
              </CollapseText>
            </ToolTip>
          </ToolTipContainer>
        )}
      </RoundQues>
    </NoSceenshotTooltip>
  );
};

const SingleCard = ({
  screenshot,
  selectedOrganization,
  selectedMemberId,
  addNotes,
  deleteScreenshot,
  user,
  allNotesIsLoading,
  addNoteIsLoading,
  deleteIsLoading,
  setSelectedVideo,
  cardSize,
  selectedCards,
  setSelectedCards,
  initialCardSize,
}) => {
  const [timeDuration, setTimeDuration] = useState(0);
  const [addNote, setAddNote] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isShowNotesModalOpen, setIsShowNotesModalOpen] = useState(false);

  const [monitorHovered, setMonitorHovered] = useState(null);
  const [screenshotHovered, setScreenshotHovered] = useState(null);

  const [showCheckBox, setShowCheckBox] = useState(false);

  const [borderColor, setBorderColor] = useState("#c2cce1");

  const onAddNoteClick = () => {
    setAddNote(!addNote);
  };

  const handleSaveNote = (note) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      screenshot &&
      screenshot.id &&
      screenshot.timesheet_id &&
      screenshot.project &&
      note
    ) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: screenshot.project.id,
        timeframe_id: screenshot.id,
        timesheet_id: screenshot.timesheet_id,
        content: note,
      };
      if (selectedMemberId) {
        payload.user_id = selectedMemberId;
      }
      addNotes(payload);
    }
    setTimeout(() => {
      setAddNote(!addNote);
    }, 1200);
  };

  useEffect(() => {
    if (screenshot.start_timestamp && screenshot.end_timestamp) {
      const duration = moment.utc(
        moment(screenshot.end_timestamp).diff(
          moment(screenshot.start_timestamp)
        )
      );
      const minutes = parseInt(moment.duration(duration).asMinutes());
      setTimeDuration(minutes);
    }
  }, [screenshot]);

  const handleDeleteScreenshot = (reason) => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      screenshot &&
      screenshot.id &&
      screenshot.timesheet_id &&
      reason
    ) {
      const userId = localStorage.getItem("user_id");
      const payload = {
        organization_id: selectedOrganization.id,
        screenshot_id: screenshot.id,
        timesheet_id: screenshot.timesheet_id,
        start: moment(screenshot.start_timestamp).utc().unix(),
        end: moment(screenshot.end_timestamp).utc().unix(),
        comment: reason,
      };
      if (
        selectedOrganization.role &&
        selectedOrganization.role !== "member" &&
        selectedMemberId &&
        userId &&
        userId !== selectedMemberId
      ) {
        payload.user_id = selectedMemberId;
      }
      deleteScreenshot(payload);
    }
  };

  const toggleDeleteScreenshot = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const toggleShowNotes = () => {
    setIsShowNotesModalOpen(!isShowNotesModalOpen);
  };
  const newCardSize =
    Number(cardSize) + (initialCardSize >= 0 ? initialCardSize : 300);

  useEffect(() => {
    if (selectedCards && selectedCards[screenshot.id]) {
      setBorderColor("#20BEAD");
    } else {
      setBorderColor("#c2cce1");
    }
  }, [selectedCards]);
  return (
    <SingleCardContainer
      style={{
        width: `${newCardSize}px`,
        borderColor: `${borderColor}`,
      }}
      onMouseEnter={() => setShowCheckBox(true)}
      onMouseLeave={() => setShowCheckBox(false)}
    >
      <CardUpperText>
        <ActivityTextSection gap="2px">
          <CommonText fontSize="13px" name title margin="0">
            {screenshot.project ? screenshot.project.name : "No project"}
          </CommonText>
          <CommonText fontSize="12px" margin="0">
            {(screenshot.task && screenshot.task.name) || "No task"}
          </CommonText>
        </ActivityTextSection>
        {((selectedCards && selectedCards[screenshot.id]) || showCheckBox) && (
          <CheckBox
            checked={selectedCards && selectedCards[screenshot.id]}
            onClick={() => {
              if (selectedCards)
                selectedCards[screenshot.id] = !selectedCards[screenshot.id];
              setSelectedCards({ ...selectedCards });
            }}
          />
        )}
        {screenshot.notes && screenshot.notes.length > 0 && (
          <NoteBadge onClick={() => toggleShowNotes()}>
            <NoteText>{`${screenshot.notes.length} Note${
              screenshot.notes.length <= 1 ? `` : `s`
            }`}</NoteText>
          </NoteBadge>
        )}
      </CardUpperText>
      <div style={{ position: `relative` }}>
        <VideoItem
          group="group"
          item={screenshot}
          screenshotBlur={
            selectedOrganization &&
            selectedOrganization.configuration &&
            selectedOrganization.configuration.screenshot_blur
          }
          onAddNoteClick={onAddNoteClick}
          toggleDeleteScreenshot={toggleDeleteScreenshot}
          selectedOrganization={
            selectedOrganization?.configuration ? selectedOrganization : null
          }
          setSelectedVideo={setSelectedVideo}
        />
        {!screenshot.thumbnails ||
          (screenshot.thumbnails.length === 0 && <InfoTooltip />)}
      </div>
      <TimeMonitorSection main>
        <CommonText
          fontSize="13px"
          name
          title
          margin="0"
        >{`${screenshot.start_timestamp.format(
          "h:mm a"
        )} - ${screenshot.end_timestamp.format("h:mm a")}`}</CommonText>
        {screenshot.activity && screenshot.activity.screen_count ? (
          <TimeMonitorSection columnGap="10px">
            {/* {screenshot.thumbnails && (
              <TimeMonitorSection
                style={{ position: "relative" }}
                onMouseEnter={() => setScreenshotHovered(screenshot.id)}
                onMouseLeave={() => setScreenshotHovered(null)}
              >
                <img src={screenshotSmall} alt="" width="16px" />
                <CommonText fontSize="12px" name>
                  {` *${parseInt(
                    screenshot.thumbnails.length /
                      screenshot.activity.screen_count
                  )}`}
                </CommonText>
                {screenshotHovered && screenshotHovered === screenshot.id && (
                  <CommonTooltip
                    tooltipText={`${parseInt(
                      screenshot.thumbnails.length /
                        screenshot.activity.screen_count
                    )} Screen Recording${
                      parseInt(
                        screenshot.thumbnails.length /
                          screenshot.activity.screen_count
                      ) > 1
                        ? "s"
                        : ""
                    }`}
                    left="-64px"
                    top="-35px"
                    width="165px"
                    height="28px"
                  />
                )}
              </TimeMonitorSection>
            )} */}
            <TimeMonitorSection
              style={{ position: "relative" }}
              onMouseEnter={() => setMonitorHovered(screenshot.id)}
              onMouseLeave={() => setMonitorHovered(null)}
            >
              <img src={monitor} alt="" width="16px" />
              <CommonText fontSize="12px" name>
                {` *${screenshot.activity.screen_count}`}
              </CommonText>
              {monitorHovered && monitorHovered === screenshot.id && (
                <CommonTooltip
                  tooltipText={`${screenshot.activity.screen_count} screens connected`}
                  left="-64px"
                  top="-35px"
                  width="160px"
                  height="28px"
                />
              )}
            </TimeMonitorSection>
          </TimeMonitorSection>
        ) : null}
      </TimeMonitorSection>

      <>
        <NoteDeleteSection
          columns={
            (selectedOrganization &&
              selectedOrganization.role &&
              selectedOrganization.role !== "member") ||
            (selectedOrganization &&
              selectedOrganization.configuration &&
              selectedOrganization.configuration.user_delete_screenshot)
              ? "auto 1fr"
              : "100%"
          }
        >
          <DeleteScreenRecordingModal
            isOpen={isDeleteModalOpen}
            toggle={toggleDeleteScreenshot}
            handleDelete={handleDeleteScreenshot}
            isLoading={deleteIsLoading}
          />
          <ShowNotesModal
            isOpen={isShowNotesModalOpen}
            toggle={toggleShowNotes}
            notes={screenshot.notes}
            startTime={screenshot.start_timestamp}
            endTime={screenshot.end_timestamp}
            project={screenshot.project}
            task={screenshot.task}
            user={user}
            notesType="single"
            isLoading={allNotesIsLoading}
          />
          <AddNoteModal
            isOpen={addNote}
            toggle={onAddNoteClick}
            saveNote={handleSaveNote}
            startTime={screenshot.start_timestamp}
            endTime={screenshot.end_timestamp}
            isLoading={addNoteIsLoading}
          />
        </NoteDeleteSection>
        {(selectedOrganization?.configuration?.mouse_track ||
          selectedOrganization?.configuration?.keystroke_track) && (
          <ActivityProductiveSection>
            <FlexSection alignItems="center">
              <LinearActivityBar
                percent={screenshot.activity ? screenshot.activity.activity : 0}
                marginTop="0"
              />
              <CommonText fontSize="13px">{`${
                screenshot.activity ? parseInt(screenshot.activity.activity) : 0
              }%`}</CommonText>
            </FlexSection>
            {screenshot.activity && (
              <CommonText fontSize="13px" title>
                Active Time{" "}
                <span style={{ color: `#20BEAD` }}>{`${Math.ceil(
                  (screenshot.activity.activity / 100) * timeDuration
                )} Minutes`}</span>
              </CommonText>
            )}
          </ActivityProductiveSection>
        )}
      </>
    </SingleCardContainer>
  );
};

export default SingleCard;
