import { useState } from "react";
import {
  SSimg,
  MagnifyIcon,
  NoteButton,
  NoteDeleteSectionInPhoto,
} from "../../../styledComponents/Activity";
import noImgThumb from "assets/img/no-image-thumb.png";

import noScreenShot from "../../../assets/img/screenshot_2.svg";
import Bean from "../../../assets/img/icons/delete.svg";
import playerIcon from "../../../assets/img/icons/player-icon.svg";
import playerIconFaded from "../../../assets/img/icons/player-icon-faded.svg";
import Note from "../../../assets/img/icons/add_note.svg";
import { WhiteButton } from "../../../styledComponents/buttons";
import { CommonImage } from "../../../styledComponents/common";
import { convertSecondsToMMSS } from "../../../utils/helper";
import { TimestampLabel } from "./screenRecordingStyles";

function VideoItem({
  item,
  toggleDeleteScreenshot,
  onAddNoteClick,
  selectedOrganization,
  setSelectedVideo,
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [imageId, setImageId] = useState(null);

  const handleMouseEnter = (id) => {
    setIsHovered(true);
    setImageId(id);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setImageId(null);
  };

  if (item.thumbnails.length) {
    return item.thumbnails.map((i, k) => {
      return (
        <div
          key={k}
          onMouseEnter={() => handleMouseEnter(k)}
          onMouseLeave={handleMouseLeave}
          style={{ position: "relative" }}
        >
          {k === 0 && i && (
            <TimestampLabel>
              {convertSecondsToMMSS(i.end_timestamp - i.start_timestamp)}
            </TimestampLabel>
          )}
          {k === 0 && (
            <img
              style={{ cursor: "pointer", width: "100%", height: "auto" }}
              key={k}
              src={`${i.thumbnail || noImgThumb}`}
              alt="screenshot"
              onClick={() => {
                setSelectedVideo();
              }}
            />
          )}
          {k === 0 && i.thumbnail && (
            <MagnifyIcon
              onClick={() => {
                setSelectedVideo();
              }}
            >
              <CommonImage
                src={isHovered ? playerIcon : playerIconFaded}
                alt="Magnify"
                width="46px"
                height="46px"
              />
            </MagnifyIcon>
          )}
          {k == imageId && isHovered && (
            <>
              <NoteDeleteSectionInPhoto>
                {(selectedOrganization &&
                  selectedOrganization.role &&
                  selectedOrganization.role !== "member") ||
                (selectedOrganization &&
                  selectedOrganization.configuration &&
                  selectedOrganization.configuration.user_delete_screenshot) ? (
                  <WhiteButton
                    type="delete"
                    height="28px"
                    width="28px"
                    padding="3px"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevents the image's onClick from being triggered
                      toggleDeleteScreenshot();
                    }}
                  >
                    <CommonImage src={Bean} alt="" width="16px" />
                  </WhiteButton>
                ) : null}
                <NoteButton
                  height="28px"
                  width="28px"
                  padding="3px"
                  onClick={(e) => {
                    e.stopPropagation();
                    onAddNoteClick();
                  }}
                >
                  <CommonImage src={Note} alt="" width="16px" />
                </NoteButton>
              </NoteDeleteSectionInPhoto>
            </>
          )}
        </div>
      );
    });
  } else {
    return (
      <div
        onMouseEnter={() => handleMouseEnter("last")}
        onMouseLeave={handleMouseLeave}
        style={{ position: "relative" }}
      >
        <SSimg
          onClick={() => {
            setSelectedVideo();
          }}
          src={noScreenShot}
          alt="no"
          width="100%"
          style={{ cursor: "pointer", width: "100%", height: "auto" }}
        />
        {imageId == "last" && isHovered && (
          <NoteDeleteSectionInPhoto>
            {(selectedOrganization &&
              selectedOrganization.role &&
              selectedOrganization.role !== "member") ||
            (selectedOrganization &&
              selectedOrganization.configuration &&
              selectedOrganization.configuration.user_delete_screenshot) ? (
              <WhiteButton
                type="delete"
                height="28px"
                width="28px"
                padding="3"
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDeleteScreenshot();
                }}
              >
                <img src={Bean} alt="" width="16px" />
              </WhiteButton>
            ) : null}
            <WhiteButton
              height="28px"
              width="28px"
              padding="3"
              onClick={(e) => {
                e.stopPropagation();
                onAddNoteClick();
              }}
            >
              <CommonImage src={Note} alt="" width="16px" />
            </WhiteButton>
          </NoteDeleteSectionInPhoto>
        )}
      </div>
    );
  }
}

export default VideoItem;
