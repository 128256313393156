import styled from "styled-components";
import { CommonFlex, CommonGrid } from "../../styledComponents/common";

export const NotificationBadge = styled.div`
  position: absolute;
  right: -10px;
  top: -8px;
  background-color: #fe5969;
  padding: ${({ sidebarMini }) => (sidebarMini ? "0px" : "2px 5px")};
  border-radius: 50%;
  height: ${({ sidebarMini }) => (sidebarMini ? "0px" : "18px")};
  width: ${({ sidebarMini }) => (sidebarMini ? "0px" : "18px")};
  text-align: center;
  color: #fff;
  font-size: 12px;
  overflow: ${({ sidebarMini }) => (sidebarMini ? "hidden" : "visible")};
`;

export const DeleteIconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  display: none;

  .notification-item:hover & {
    display: block;
  }
`;

export const NotificationContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: ${({ isVisible }) => (isVisible ? "16px" : "0")};
  max-width: ${({ isVisible }) => (isVisible ? "400px" : "0")};
  width: 400px;
  margin: 0 auto;
  position: absolute;
  background-color: #fff;
  top: 30px;
  left: 10px;
  z-index: 2001;
  height: ${({ isVisible }) => (isVisible ? "auto" : "0")};
  transition: height 0.3s, max-height 0.3s;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.26);
  cursor: default;
`;

export const NotificationHeader = styled(CommonFlex)`
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const NotificationItem = styled(CommonGrid)`
  margin-bottom: 16px;
  position: relative;
  gap: 5px;
  grid-template-columns: 50px 1fr 30px;
  &.notification-item:hover ${DeleteIconWrapper} {
    display: block;
  }
`;

export const NotificationTitle = styled.div`
  margin: 0;
  color: rgba(61, 77, 105, 0.8);
`;

export const HighlightedText = styled.span`
  font-weight: 700;
`;
