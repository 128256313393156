import { useState, useEffect, useMemo, useRef } from "react";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
import CustomScrollbars from "../../components/CustomScrollbars/CustomScrollbars";

import {
  SidebarContainer,
  SidebarCollapseButton,
  SidebarCollapseButtonIcon,
  LogoContainer,
  LogoLink,
  LogoIconDiv,
  LogoIcon,
  LogoText,
  SidebarLinksWrapper,
  SidebarNav,
  LinkGroup,
  LinkGroupTitle,
  OrgAndProfileContainer,
  OrgIcon,
  PictureTooltipContainer,
  ProfilePicture,
  PopupArrowDiv,
  PopupArrowIcon,
} from "./sidebarStyles";
import {
  CenteredTooltip,
  CommonFlex,
  CommonImage,
  CommonText,
} from "../../styledComponents/common";

import { generateGroupedLinks } from "./sidebarHelpers";
import { useCollapseState } from "./hooks/useCollapseState";
import { RegularLink, CollapsibleLink } from "./components/SidebarLinks";

import SeeMoreView from "./components/SeeMoreView";
import SidebarSkeleton from "./components/SidebarSkeleton";
// import ProfilePopup from "./ProfilePopup";
import OrgAndProfilePopup from "./components/OrgAndProfilePopup";

import routes from "../../routes";

import logo from "../../assets/img/logo/logo_icon.svg";
import logoTxt from "../../assets/img/logo/logo_text.svg";

import hamburgerIcon from "../../assets/img/icons/hamburger.svg";
import arrowDown from "../../assets/img/Sidebar/sidebar-arrow-down.svg";
import arrowUp from "../../assets/img/Sidebar/sidebar-arrow-up.svg";
import arrowWhite from "../../assets/img/icons/arrow-white.svg";
import bellIcon from "../../assets/img/icons/bell_Icon.svg";

import { getTwoLetter } from "../../utils/helper";
import { clearLocalStorage } from "../../utils/handleLogout";
import NotificationsPanel from "../Notification/notificationDropdown";
import { NotificationBadge } from "../Notification/notificationStyles";
const OrgAndProfileSection = (props) => {
  const [orgAndProfilePopupOpen, setOrgAndProfilePopupOpen] = useState(false);

  const [activeOrgsList, setActiveOrgsList] = useState([]);
  const [archivedOrgsList, setArchivedOrgsList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState(
    localStorage.getItem("selected_organization_id")
  );
  const [selectedOrgName, setSelectedOrgName] = useState(
    localStorage.getItem("selected_organization")
  );
  const [selectedOrgColor, setSelectedOrgColor] = useState("");

  const {
    selectedOrganization,
    selectOrganization,
    organizations,
    history,
    loginProfileCreatedReset,
    sidebarMini,
    clearNavOpen,
    profile,
  } = props;
  const selectFirstActiveOrg = (list) => {
    const orgList =
      list &&
      list.length &&
      list.filter((organization) => organization.is_active === true);
    if (orgList && orgList.length > 0) {
      selectOrg(orgList[0]);
    }
  };

  useEffect(() => {
    if (organizations && organizations.list) {
      if (!organizations.selectedOrganization) {
        const selectedOrgId = localStorage.getItem("selected_organization_id");
        if (selectedOrgId) {
          let selectedOrg;
          if (organizations.list && organizations.list.length) {
            organizations.list.some((organization) => {
              if (organization.id === selectedOrgId) {
                selectedOrg = organization;
                return true;
              }
              return false;
            });
          }
          if (selectedOrg) {
            selectOrg(selectedOrg);
          } else {
            selectFirstActiveOrg(organizations.list);
          }
        } else {
          selectFirstActiveOrg(organizations.list);
        }
      }
      if (organizations.list.length > 0) {
        const activeList = [];
        const archivedList = [];
        organizations.list.map((org, index) => {
          if (org.is_active) {
            activeList.push(org);
          } else {
            archivedList.push(org);
          }
        });
        setActiveOrgsList(activeList);
        setArchivedOrgsList(archivedList);
      } else if (organizations.list.length === 0) {
        setActiveOrgsList([]);
        setArchivedOrgsList([]);
      }
    }
    if (
      organizations &&
      organizations.selectedOrganization &&
      (!selectedOrgName ||
        !selectedOrgColor ||
        selectedOrgId !== organizations.selectedOrganization.id ||
        selectedOrgName !== organizations.selectedOrganization.name) &&
      organizations.selectedOrganization.id
    ) {
      localStorage.setItem(
        "selected_organization",
        organizations.selectedOrganization.name
      );
      localStorage.setItem(
        "selected_organization_id",
        organizations.selectedOrganization.id
      );
      setSelectedOrgId(organizations.selectedOrganization.id);
      setSelectedOrgName(organizations.selectedOrganization.name);
      setSelectedOrgColor(organizations.selectedOrganization.color);
    }
  }, [organizations]);

  let userFirstName;
  const userFullName = localStorage.getItem("user_fullName");
  if (userFullName) {
    userFirstName = userFullName.split(" ")[0];
  }
  const userProfileColor = localStorage.getItem("user_profile_color");
  const userEmail = localStorage.getItem("email");

  const selectOrg = (item) => {
    localStorage.setItem("selected_organization", item.name);
    localStorage.setItem("selected_organization_id", item.id);
    localStorage.setItem("selected_organization_role", item.role);
    selectOrganization(item);
  };

  const closePopup = () => {
    setOrgAndProfilePopupOpen(false);
  };

  const handleLogout = () => {
    clearLocalStorage();
    history.push("/auth/login");
    loginProfileCreatedReset();
  };
  return (
    <OrgAndProfileContainer sidebarMini={sidebarMini}>
      <OrgAndProfilePopup
        closePopup={closePopup}
        popupOpen={orgAndProfilePopupOpen}
        sidebarMini={sidebarMini}
        history={history}
        handleLogout={handleLogout}
        clearNavOpen={clearNavOpen}
        profilePicture={
          (profile && profile.profileData && profile.profileData.thumbnail) ||
          null
        }
        userTimezone={
          profile && profile.profileData && profile.profileData.timezone
        }
        userFullName={userFullName}
        userProfileColor={userProfileColor}
        userEmail={userEmail}
        selectedOrganization={selectedOrganization}
        selectOrg={selectOrg}
        selectedOrgId={selectedOrgId}
        activeOrgsList={activeOrgsList}
        archivedOrgsList={archivedOrgsList}
      />
      {selectedOrganization && selectedOrganization.id && (
        <OrgIcon
          bgColor={selectedOrganization && selectedOrganization.color}
          active={selectedOrganization && selectedOrganization.is_active}
          margin="0 -10px 0 0"
          $size={sidebarMini && "30px"}
          fontSize={sidebarMini ? "12px" : "14px"}
          onClick={() => setOrgAndProfilePopupOpen(true)}
        >
          <CenteredTooltip left="0px" top="-35px">
            {selectedOrgName}
          </CenteredTooltip>
          {selectedOrgName && getTwoLetter(selectedOrgName)}
        </OrgIcon>
      )}

      <PictureTooltipContainer>
        {profile && profile.profileData && profile.profileData.thumbnail ? (
          <ProfilePicture
            $size={sidebarMini && "30px"}
            cursor="pointer"
            src={profile.profileData.thumbnail}
            alt=""
            onClick={() => setOrgAndProfilePopupOpen(true)}
          />
        ) : (
          <FirstRoundLetterComp
            onClick={() => setOrgAndProfilePopupOpen(true)}
            backColor={1}
            size="37px"
            text={userFullName}
            color={userProfileColor || null}
          />
        )}

        <CenteredTooltip left="0px" top="-35px">
          {userFullName}
        </CenteredTooltip>
      </PictureTooltipContainer>

      <PopupArrowDiv onClick={() => setOrgAndProfilePopupOpen(true)}>
        <PopupArrowIcon
          marginLeft="0"
          src={arrowWhite}
          alt=""
          popupOpen={orgAndProfilePopupOpen}
        />
      </PopupArrowDiv>
    </OrgAndProfileContainer>
  );
};

const Sidebar = (props) => {
  const notificationSectionRef = useRef(null);

  const [windowWidth, setWindowWidth] = useState(0);
  const [sidebarLinks, setSidebarLinks] = useState(null);
  const [collapseState, toggleCollapse] = useCollapseState(
    routes,
    props.location
  );

  const [isLoading, setIsLoading] = useState(true);
  const [showSidebarToggle, setShowSidebarToggle] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const {
    selectedOrganization,
    selectOrganization,
    getOrganizationList,
    organizations,
    profile,
    clearNavOpen,
    history,
    sidebarMini,
    loginProfileCreatedReset,
    location,
    handleMiniClick,
    navOpen,
  } = props;

  useEffect(() => {
    updateSize();
    window.addEventListener("resize", updateSize);

    getOrganizationList();

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  useEffect(() => {
    if (selectedOrganization?.role && selectedOrganization?.plan?.id) {
      setIsLoading(false);
    }
  }, [selectedOrganization?.role, selectedOrganization?.plan?.id]);

  const memoizedLinks = useMemo(
    () => generateGroupedLinks(routes, selectedOrganization),
    [routes, selectedOrganization?.role, selectedOrganization?.plan?.id]
  );

  useEffect(() => {
    setSidebarLinks(memoizedLinks);
  }, [memoizedLinks]);

  const updateSize = () => {
    setWindowWidth(window.innerWidth);
  };

  const renderLink = (link, index) => {
    if (!selectedOrganization) return null;

    return link.collapse ? (
      <CollapsibleLink
        path={link}
        key={index}
        index={index}
        collapseState={collapseState}
        toggleCollapse={toggleCollapse}
        organization={selectedOrganization}
        location={location}
        sidebarMini={sidebarMini}
      />
    ) : (
      <RegularLink
        key={index}
        path={link}
        location={location}
        sidebarMini={sidebarMini}
      />
    );
  };

  return (
    <SidebarContainer
      sidebarMini={sidebarMini}
      navOpen={navOpen}
      onMouseEnter={() => setShowSidebarToggle(true)}
      onMouseLeave={() => setShowSidebarToggle(false)}
    >
      {windowWidth >= 992 && showSidebarToggle && (
        // <HamburgerIconContainer>
        //   <HamburgerIcon
        //     src={hamburgerIcon}
        //     alt=""
        //     onClick={() => handleMiniClick()}
        //   />
        // </HamburgerIconContainer>
        <SidebarCollapseButton onClick={() => handleMiniClick()}>
          <SidebarCollapseButtonIcon
            sidebarMini={sidebarMini}
            src={arrowDown}
            alt=""
          />
        </SidebarCollapseButton>
      )}

      {/* <CommonFlex gap="10px"> */}
      <LogoContainer
      // style={{ paddingRight: "10px" }}
      >
        <LogoLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.apploye.com"
        >
          <LogoIconDiv>
            <LogoIcon src={logo} alt="" />
          </LogoIconDiv>
        </LogoLink>
        <LogoLink
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.apploye.com"
        >
          <LogoText src={logoTxt} alt="" />
        </LogoLink>
      </LogoContainer>
      {/* {!sidebarMini && (
          <div
            ref={notificationSectionRef}
            style={{
              position: "relative",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => {
                setIsVisible(!isVisible);
              }}
              style={{
                position: "relative",
              }}
            >
              {props.notifications?.filter((notification) => !notification.read)
                .length > 0 && (
                <NotificationBadge sidebarMini={sidebarMini}>
                  <CommonText fontSize="9px" color="#fff">
                    {props.notifications?.filter(
                      (notification) => !notification.read
                    ).length > 9
                      ? "9+"
                      : props.notifications?.filter(
                          (notification) => !notification.read
                        ).length}
                  </CommonText>
                </NotificationBadge>
              )}
              {!sidebarMini && <CommonImage src={bellIcon} />}
            </div>
            <NotificationsPanel
              {...{
                isVisible,
                setIsVisible,
                notificationSectionRef,
              }}
              deleteNotification={props.deleteNotification}
              notifications={props.notifications}
              history={history}
              selectedOrganization={selectedOrganization}
              markAsRead={props.markAsRead}
            />
          </div>
        )} */}
      {/* </CommonFlex> */}
      {/* {sidebarMini && (
        <CommonFlex>
          <div
            ref={notificationSectionRef}
            style={{
              position: "relative",
              cursor: "pointer",
              margin: "auto",
            }}
          >
            <div
              onClick={() => {
                setIsVisible(!isVisible);
              }}
              style={{
                position: "relative",
                marginRight: "4px",
              }}
            >
              {props.notifications?.filter((notification) => !notification.read)
                .length > 0 && (
                <NotificationBadge sidebarMini={!sidebarMini}>
                  <CommonText fontSize="9px" color="#fff">
                    {props.notifications?.filter(
                      (notification) => !notification.read
                    ).length > 9
                      ? "9+"
                      : props.notifications?.filter(
                          (notification) => !notification.read
                        ).length}
                  </CommonText>
                </NotificationBadge>
              )}
              {sidebarMini && <CommonImage src={bellIcon} />}
            </div>
            <NotificationsPanel
              {...{
                isVisible,
                setIsVisible,
                notificationSectionRef,
              }}
              deleteNotification={props.deleteNotification}
              notifications={props.notifications}
              history={history}
              selectedOrganization={selectedOrganization}
              markAsRead={props.markAsRead}
            />
          </div>
        </CommonFlex>
      )} */}
      <CustomScrollbars
        style={{
          height: `calc(100vh - 137px)`,
          zIndex: 4,
        }}
      >
        {isLoading ? (
          <SidebarSkeleton />
        ) : (
          <SidebarLinksWrapper sidebarMini={sidebarMini}>
            {sidebarLinks &&
              Object.keys(sidebarLinks).map((group, index) => (
                <LinkGroup key={index}>
                  {!sidebarMini && <LinkGroupTitle>{group}</LinkGroupTitle>}
                  <SidebarNav>
                    {sidebarLinks[group]?.length > 0 &&
                      sidebarLinks[group].map((link, index) =>
                        renderLink(link, index)
                      )}
                  </SidebarNav>
                </LinkGroup>
              ))}
            <SeeMoreView
              organization={selectedOrganization}
              location={location}
            />
          </SidebarLinksWrapper>
        )}
      </CustomScrollbars>
      <OrgAndProfileSection
        sidebarMini={sidebarMini}
        history={history}
        profile={profile}
        loginProfileCreatedReset={loginProfileCreatedReset}
        clearNavOpen={clearNavOpen}
        organizations={organizations}
        selectedOrganization={selectedOrganization}
        selectOrganization={selectOrganization}
      />
    </SidebarContainer>
  );
};

export default Sidebar;
