import React from "react";
import ReactPlayer from "react-player";
import "../../assets/css/style.css"; // Ensure the CSS file is imported

const VideoPlayer = ({ video, onEnded }) => {
  return (
    <div className="video-wrapper">
      <ReactPlayer
        url={video}
        playing
        controls
        className="react-video-player"
        onError={(e) => console.error("Error playing video:", e)}
        onEnded={() => onEnded && onEnded()}
      />
    </div>
  );
};

export default VideoPlayer;
