import styled, { css, keyframes } from "styled-components";

const flyIn = keyframes`
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const flyOut = keyframes`
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
`;

export const ToolbarContainer = styled.div`
  position: sticky;
  top: calc(100vh - 60px);
  z-index: 100;
  height: 0;
`;

export const ToolbarContent = styled.div`
  display: flex;
  align-items: center;
  background-color: black;
  color: white;
  padding: 8px;
  border-radius: 8px;
  gap: 16px;
  z-index: 100;
  width: max-content;
  margin: 0 auto;
  font-size: 12px;
  border: medium solid white;
  animation: ${({ selected }) =>
    selected === true
      ? css`
          ${flyOut} 0.5s ease-out
        `
      : css`
          ${flyIn} 0.5s ease-in
        `};
  animation-fill-mode: both;
`;

export const SelectedCountContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #333;
  justify-content: space-between;
  padding: 4px 12px;
  border-radius: 8px;
  min-width: 115px;
`;

export const UnselectAllButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background-color: #fff;
  padding: 0.5px 2px;
  border-radius: 50%;
  color: black;
  font-size: 9px;
  font-weight: bold;
  margin-left: 8px;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
`;

export const SelectAllContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

export const ToolbarButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => (props.color ? props.color : "white")};
  cursor: pointer;
  background: none;
  border: none;
`;

export const TimestampLabel = styled.div`
  padding: 2px 5px;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  border-radius: 5px;
  font-size: 11px;
  bottom: 5px;
  right: 5px;
  color: white;
`;
