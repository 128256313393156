import { useState } from "react";
import { Table, Button } from "reactstrap";

import styled from "styled-components";
import SweetAlert from "react-bootstrap-sweetalert";
import "../../assets/scss/custom.scss";
import OrganizationDeleteModal from "./OrganizationDeleteModal";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Select from "../../components/ReactSelectDropdown";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
import DropdownStyle from "./DropdownStyle";

import { currencySymbolMap } from "../../utils/currencies";

import { TableItem } from "../../styledComponents/teams";
import {
  TableText,
  RoundLetterSection,
  FirstWordRound,
} from "../../styledComponents/common";
import { OrgIconDiv } from "../Sidebar/components/popupStyles";
import { getTwoLetter } from "../../utils/helper";

const activeActionOptions = [
  {
    value: "edit",
    label: "Edit",
  },
  { value: "archive", label: "Archive" },
  { value: "delete", label: "Delete" },
];
const archivedActionOptions = [
  {
    value: "edit",
    label: "Edit",
  },
  { value: "unarchive", label: "Unarchive" },
  { value: "delete", label: "Delete" },
];

const TableHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1.5fr 110px 160px 110px 140px;
  grid-column-gap: 4px;
  justify-content: space-between;
  padding: 0 30px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.secondary};
  @media (max-width: 1250px) {
    grid-template-columns: 220px 220px 110px 160px 110px 140px;
    grid-column-gap: 6px;
  }
`;
const TableBodyContainer = styled(TableHeaderContainer)`
  border-bottom: none;
  :nth-of-type(even) {
    background-color: #f8f9fc;
  }
`;

export const RoundLetter = ({ text, backColor, size, fontSize, gap, org }) => {
  const currentOrg = localStorage.getItem("selected_organization_id");
  return (
    <RoundLetterSection gap={gap}>
      <OrgIconDiv size={"42px"} selected={org === currentOrg}>
        <FirstWordRound backColor={backColor} size={size} fontSize={fontSize}>
          {getTwoLetter(text)}
        </FirstWordRound>
      </OrgIconDiv>
      <TableText name>{text}</TableText>
    </RoundLetterSection>
  );
};

const OrganizationsTable = (props) => {
  const [archiveItem, setArchiveItem] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const currentOrg = localStorage.getItem("selected_organization_id");

  const { toggleArchive, deleteOrganization, organizationsList, edit } = props;

  const handleToggleArchive = (org = null) => {
    setArchiveItem(org);
  };

  const handleArchiveConfirm = () => {
    toggleArchive(archiveItem);
    setArchiveItem(null);
  };

  const toggleDeleteItem = (org = null) => {
    setDeleteItem(org);
  };

  const handleDeleteItemConfirm = (password, agreed) => {
    if (deleteItem && deleteItem.id) {
      deleteOrganization({
        id: deleteItem.id,
        password: password,
        agreed: agreed,
      });
      setDeleteItem(null);
    }
  };

  const onActionChange = (action, org) => {
    switch (action) {
      case "edit":
        edit(org.id);
        break;
      case "archive":
      case "unarchive":
        handleToggleArchive(org);
        break;
      case "delete":
        toggleDeleteItem(org);
        break;
      default:
      // do nothing
    }
  };

  return (
    <>
      <OrganizationDeleteModal
        isOpen={deleteItem !== null}
        organization={deleteItem}
        toggle={toggleDeleteItem}
        deleteOrg={handleDeleteItemConfirm}
      />
      <>
        <TableHeaderContainer>
          <TableItem>
            <TableText>Name</TableText>
          </TableItem>
          <TableItem>
            <TableText>Address</TableText>
          </TableItem>
          <TableItem>
            <TableText>Code</TableText>
          </TableItem>
          <TableItem>
            <TableText>Timezone</TableText>
          </TableItem>
          <TableItem>
            <TableText>Currency</TableText>
          </TableItem>
          <TableItem>
            <TableText>Action</TableText>
          </TableItem>
        </TableHeaderContainer>
        {organizationsList &&
          organizationsList.map((org, i) => {
            return (
              <TableBodyContainer key={i}>
                <TableItem>
                  {/* <RoundLetter
                    text={org.name}
                    backColor={org.is_active ? org.color : "#9eafc2"}
                  /> */}
                  {org.is_active ? (
                    <RoundLetterSection>
                      <OrgIconDiv
                        size={"42px"}
                        selected={org.id && org.id === currentOrg}
                      >
                        <FirstRoundLetterComp
                          size="34px"
                          fontSize="13px"
                          text={org.name}
                          backColor={i}
                          color={org.color || null}
                        />
                      </OrgIconDiv>
                      <TableText name>{org.name}</TableText>
                    </RoundLetterSection>
                  ) : (
                    <RoundLetter
                      org={org.id}
                      size="34px"
                      fontSize="13px"
                      text={org.name}
                      backColor="#9eafc2"
                    />
                  )}
                </TableItem>
                <TableItem>
                  <TableText name>{org.address || "-"}</TableText>
                </TableItem>
                <TableItem>
                  <CopyToClipboard text={org.code}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "#6686A2",
                        color: "#fff",
                        borderRadius: "6px",
                        width: "90px",
                        height: "28px",
                      }}
                    >
                      {org.code}
                    </div>
                  </CopyToClipboard>
                </TableItem>
                <TableItem>
                  <TableText name>{org.timezone}</TableText>
                </TableItem>
                <TableItem>
                  <TableText name>
                    {org.configuration && org.configuration?.currency} (
                    {currencySymbolMap[org.configuration?.currency]})
                  </TableText>
                </TableItem>
                <TableItem>
                  <Select
                    isSearchable={false}
                    isDisabled={org.role !== "owner"}
                    value={null}
                    options={
                      org.is_active
                        ? activeActionOptions
                        : archivedActionOptions
                    }
                    onChange={(e) =>
                      org.role === "owner" && onActionChange(e.value, org)
                    }
                    placeholder="Actions"
                    styles={DropdownStyle(null)}
                  />
                </TableItem>
              </TableBodyContainer>
            );
          })}
      </>
      {archiveItem && (
        <SweetAlert
          title="Are you sure"
          showCancel
          onConfirm={handleArchiveConfirm}
          onCancel={() => handleToggleArchive()}
          confirmBtnText={archiveItem.is_active ? "Archive" : "Unarchive"}
          confirmBtnBsStyle="primary"
          cancelBtnBsStyle="default"
        >
          {archiveItem.is_active
            ? "Do you really want to archive your organization?"
            : "Do you really want to make it active?"}
        </SweetAlert>
      )}
    </>
  );
};

export default OrganizationsTable;
