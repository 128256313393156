import moment from "moment";
import React, { useState, useEffect } from "react";
import {
  CommonFlex,
  CommonGrid,
  CommonImage,
  Container,
  RoundMiniImage,
} from "../../styledComponents/common";
import {
  AssigneesManageRoundImage,
  Cross,
  CrossIcon,
} from "../../styledComponents/createProject";
import crossIcon from "../../assets/img/icons/cross_black.svg";
import { PageTitle, HeaderContainer } from "../../styledComponents/common";
import FirstRoundLetterComp from "../../components/FirstRoundLetter/FirstRoundLetter";
import settingsIcon from "../../assets/img/Sidebar/settings.svg";
import ClearAllNotificationWarningModal from "./ClearAllNotificationWarningModal";
import NoDataComponent from "../../components/NoDataComponent/NoDataComponent";
import ComponentCircleLoader from "../../components/Loaders/ComponentCircleLoader";

const Notifications = ({
  notifications,
  fetchNotifications,
  deleteNotification,
  selectedOrganization,
  markAllAsRead,
  deleteMultipleNotifications,
  markMultipleAsRead,
  history,
  markAsRead,
  clearAllNotifications,
  isLoading,
}) => {
  // useEffect(() => {
  //   if (selectedOrganization?.id) {
  //     fetchNotifications({
  //       organizationId: selectedOrganization.id,
  //     });
  //   }
  // }, [selectedOrganization?.id]);

  const handleDeleteNotification = (id) => {
    if (id && selectedOrganization?.id)
      deleteNotification({ id, organizationId: selectedOrganization.id });
  };

  const handleMarkAllAsRead = () => {
    selectedOrganization?.id &&
      markAllAsRead({ organizationId: selectedOrganization.id });
  };
  const handleMarkAsRead = (id) => {
    selectedOrganization?.id &&
      markAsRead({ id, organizationId: selectedOrganization.id });
  };

  const formatDate = (date) => {
    const now = moment();
    const diffInMinutes = now.diff(moment(date), "minutes");
    const diffInHours = now.diff(moment(date), "hours");
    const diffInDays = now.diff(moment(date), "days");

    if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else if (diffInDays < 2) {
      return `Yesterday at ${moment(date).format("h:mm a")}`;
    } else {
      return moment(date).format("MMM D [at] h:mm a");
      // return moment(date).format("MMM D, YYYY [at] h:mm a");
    }
  };

  const [isClearAllModalOpen, setIsClearAllModalOpen] = useState(false);

  const toggleClearAllModal = () => {
    setIsClearAllModalOpen(!isClearAllModalOpen);
  };

  const handleClearAllNotifications = () => {
    selectedOrganization?.id &&
      clearAllNotifications({ organizationId: selectedOrganization.id });
    setIsClearAllModalOpen(false);
  };
  const handleClearAllClick = () => {
    if (
      notifications?.filter((notification) => !notification.read).length > 0
    ) {
      toggleClearAllModal();
    } else handleClearAllNotifications();
  };

  return (
    <div className="content">
      <ClearAllNotificationWarningModal
        isOpen={isClearAllModalOpen}
        toggle={toggleClearAllModal}
        clearAllNotifications={handleClearAllNotifications}
      />
      <PageTitle>Notifications</PageTitle>
      <CommonFlex
        style={{
          justifyContent: "end",
          marginTop: "0px",
          marginBottom: "30px",
          alignItems: "center",
        }}
      >
        <CommonFlex>
          <div
            style={{
              cursor:
                notifications?.filter((notification) => !notification.read)
                  .length > 0
                  ? "pointer"
                  : "not-allowed",
              color: "#6D6D6D",
            }}
            onClick={() => {
              notifications?.filter((notification) => !notification.read)
                .length > 0 && handleMarkAllAsRead();
            }}
          >
            Mark All as Read
          </div>
          <div
            style={{
              cursor: notifications?.length > 0 ? "pointer" : "not-allowed",
              color: "#FE5969",
            }}
            onClick={() => {
              notifications?.length > 0 && handleClearAllClick();
            }}
          >
            Clear All
          </div>

          {/* <div style={{ cursor: "pointer" }}>
            <CommonImage src={settingsIcon} size="20px" />
          </div> */}
        </CommonFlex>
      </CommonFlex>

      <Container padding="20px">
        {isLoading ? (
          <ComponentCircleLoader />
        ) : (
          <CommonFlex direction="column" style={{ width: "100%" }}>
            {notifications?.length === 0 ? (
              <NoDataComponent
                title="No notifications found."
                padding="5vh 0"
                imageHeight="140px"
              />
            ) : (
              notifications?.map((notification) => {
                const {
                  id,
                  title,
                  created_at,
                  read,
                  user: { fullname, thumbnail },
                } = notification;
                const formattedTitle = title.replace(
                  /~~([^~]+)~~/g,
                  (match, p1) => `<b>${p1}</b>`
                );
                return (
                  <CommonGrid
                    key={id}
                    columns="50px 2fr 120px 60px"
                    style={{ width: "100%" }}
                    alignItem="center"
                  >
                    {thumbnail ? (
                      <AssigneesManageRoundImage imageSize={"40px"}>
                        <RoundMiniImage
                          src={thumbnail}
                          alt=""
                          imageSize={"40px"}
                        />
                      </AssigneesManageRoundImage>
                    ) : (
                      <FirstRoundLetterComp text={fullname} />
                    )}
                    <CommonFlex
                      onClick={() => {
                        !read && handleMarkAsRead(id);
                        manageNotificationActions({ history, notification });
                      }}
                      style={{
                        cursor: "pointer",
                        margin: 0,
                      }}
                    >
                      <span
                        dangerouslySetInnerHTML={{ __html: formattedTitle }}
                      />
                    </CommonFlex>
                    <div style={{ fontSize: "0.8em", color: "#888" }}>
                      {formatNotificationDate(created_at)}
                    </div>
                    <CommonFlex>
                      {read ? (
                        <div
                          style={{
                            backgroundColor: "#6F7A8E",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            backgroundColor: "#4D94FB",
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                          }}
                        />
                      )}
                      {
                        <Cross onClick={() => handleDeleteNotification(id)}>
                          <CrossIcon src={crossIcon} alt="" />
                        </Cross>
                      }
                    </CommonFlex>
                  </CommonGrid>
                );
              })
            )}
          </CommonFlex>
        )}{" "}
      </Container>
    </div>
  );
};

export default Notifications;

export const formatNotificationDate = (date) => {
  const now = moment();
  const diffInMinutes = now.diff(moment(date), "minutes");
  const diffInHours = now.diff(moment(date), "hours");
  const diffInDays = now.diff(moment(date), "days");

  if (diffInMinutes < 1) {
    return `Just now`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes}m`;
  } else if (diffInHours < 24) {
    return `${diffInHours}h`;
  } else if (diffInDays < 2) {
    return `Yesterday at ${moment(date).format("h:mm a")}`;
  } else {
    return moment(date).format("MMM D [at] h:mm a");
  }
};

export const manageNotificationActions = ({ history, notification }) => {
  const { notification_type, object_ids } = notification;
  const role = localStorage.getItem("selected_organization_role");

  switch (notification_type.code_text) {
    case "PROJECT_MEMBER_ADDED":
      if (object_ids.project_id && role !== "member") {
        history.push(`/user/project-details?id=${object_ids.project_id}`);
      } else history.push(`/user/projects`);
      break;
    case "PROJECT_MEMBER_REMOVED":
      if (object_ids.user_id && role !== "member") {
        history.push(`/user/member-details?id=${object_ids.user_id}`);
      } else history.push(`/user/projects`);
      break;
    case "TASK_MEMBER_ADDED":
      if (object_ids.project_id && role !== "member") {
        history.push(`/user/project-details?id=${object_ids.project_id}`);
      } else history.push(`/user/tasks`);
      break;
    case "TASK_MEMBER_REMOVED":
      if (object_ids.project_id && role !== "member") {
        history.push(`/user/project-details?id=${object_ids.project_id}`);
      } else history.push(`/user/tasks`);
      break;
    case "ORGANIZATION_MEMBER_ADDED":
      history.push(`/user/members`);
      break;
    case "ORGANIZATION_MEMBER_REMOVED":
      break;
    default:
      break;
  }
};
