import check from "../../assets/img/icons/check.svg";
import checkGrey from "../../assets/img/icons/check-grey.svg";
import { CustomReportCheckboxContainer } from "../../custom_modules/ReportsNew/CustomReport/customReportStyles";
const CheckBox = ({
  onClick,
  checked,
  size = "23px",
  checkSize = "12px",
  useGrey = false,
}) => {
  return (
    <CustomReportCheckboxContainer
      onClick={onClick}
      size={size}
      bgColor={useGrey ? "#FCFDFE" : ""}
      noHover={useGrey}
      borderColor={useGrey ? "#c2cce1" : ""}
    >
      {checked && (
        <img
          src={useGrey ? checkGrey : check}
          alt=""
          height={checkSize}
          width={checkSize}
        />
      )}
    </CustomReportCheckboxContainer>
  );
};

export default CheckBox;
