import { connect } from "react-redux";
import Notifications from "./notifications";
import {
  fetchNotifications,
  deleteNotification,
  deleteMultipleNotifications,
  markAllAsRead,
  markMultipleAsRead,
  markAsRead,
  clearAllNotifications,
} from "./notificationsActions";

const mapStateToProps = (state) => ({
  notifications: state.notification.notifications,
  selectedOrganization: state.organization.selectedOrganization,
  isLoading: state.notification.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: (params) => dispatch(fetchNotifications({ ...params })),
  deleteNotification: (params) => dispatch(deleteNotification({ ...params })),
  markAllAsRead: (params) => dispatch(markAllAsRead({ ...params })),
  deleteMultipleNotifications: (params) =>
    dispatch(deleteMultipleNotifications({ ...params })),
  markMultipleAsRead: (params) => dispatch(markMultipleAsRead({ ...params })),
  markAsRead: (params) => dispatch(markAsRead({ ...params })),
  clearAllNotifications: (params) => dispatch(clearAllNotifications({ ...params })),
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
