import { connect } from "react-redux";

// Component
import Sidebar from "./Sidebar";

import {
  getOrganizationList,
  selectOrganization,
} from "../Organization/OrganizationActions";
import { loginProfileCreatedReset } from "../Login/loginActions";
import { deleteNotification, markAsRead } from "../Notification/notificationsActions";

// Store props to map with current state
const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth,
  organizations: state.organization,
  selectedOrganization: state.organization.selectedOrganization,
  notifications: state.notification.notifications,
});

// Actions prop to dispatch
const mapDispatchToProps = (dispatch) => ({
  getOrganizationList: () => dispatch(getOrganizationList()),
  selectOrganization: (data) => dispatch(selectOrganization(data)),
  loginProfileCreatedReset: () => dispatch(loginProfileCreatedReset()),
  markAsRead: (data) => dispatch(markAsRead(data)),
  deleteNotification: (data) => dispatch(deleteNotification(data)),
});

// connect states and dispatchers with components
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
