import { useState, useEffect } from "react";
import FilterDropDownStyle from "../../components/DropdownStyle/FilterDropDownStyle";
import AddSingleMemberDropdown from "../../components/CustomDropdown/AddSingleMemberDropdown";

import ProjectDropdown from "./ProjectDropdown";
import TaskDropdown from "./TaskDropdown";

import {
  FilterLabel,
  InputWithLabelSection,
} from "../../styledComponents/common";
import { FilterInnerSection } from "../../styledComponents/Activity";
import { getFullName } from "../../utils/helper";

const CommonFilter = (props) => {
  const [memberOptions, setMemberOptions] = useState([]);

  const {
    selectedOrganization,
    getOrganizationMembersList,

    organizationMembersList,
    getProjectAssigneeList,
    projectAssigneeList,
    getTaskAssigneeList,
    taskAssigneeList,

    selectedProject,
    selectedTask,
    selectProject,
    selectTask,
    selectedMember,
    selectMember,
    clearMember,
    columns,
    isLocation,
    addAllProjects,
    addAllTasksOption,
  } = props;

  useEffect(() => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
      };
      getOrganizationMembersList(payload);
    }
  }, [selectedOrganization]);

  useEffect(() => {
    if (organizationMembersList && organizationMembersList.length > 0) {
      setMemberOptions(organizationMembersList);
    } else if (
      organizationMembersList &&
      organizationMembersList.length === 0
    ) {
      setMemberOptions([]);
    }
  }, [organizationMembersList]);

  useEffect(() => {
    if (projectAssigneeList && projectAssigneeList.length > 0) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          avatar: assignee.user.avatar || null,
          color: assignee.user.color || null,
          timezone: assignee.user.timezone || null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (projectAssigneeList && projectAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [projectAssigneeList]);

  useEffect(() => {
    if (taskAssigneeList && taskAssigneeList.length > 0) {
      let assigneeList = [];
      taskAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.id,
          name: getFullName(assignee),
          avatar: assignee.avatar || null,
          timezone: assignee.timezone || null,
          color: assignee.color || null,
        });
      });
      setMemberOptions(assigneeList);
    } else if (taskAssigneeList && taskAssigneeList.length === 0) {
      setMemberOptions([]);
    }
  }, [taskAssigneeList]);

  useEffect(() => {
    if ((selectedProject && !selectedProject.value) || !selectedProject) {
      setMemberOptions(organizationMembersList);
    }
  }, [selectedProject]);

  useEffect(() => {
    if (
      selectedTask &&
      !selectedTask.value &&
      projectAssigneeList &&
      projectAssigneeList.length > 0 &&
      selectedProject?.value
    ) {
      let assigneeList = [];
      projectAssigneeList.map((assignee) => {
        assigneeList.push({
          id: assignee.user.id,
          name: getFullName(assignee.user),
          avatar: assignee.user.avatar || null,
        });
      });

      setMemberOptions(assigneeList);
    }
  }, [selectedTask]);

  const onProjectSelect = (e) => {
    if (selectProject) {
      selectProject(e);
    }
    if (selectedOrganization && selectedOrganization.id && e && e.value) {
      let payload = {
        organization_id: selectedOrganization.id,
        project_id: e.value,
      };
      getProjectAssigneeList(payload);
    }
  };

  const onTaskSelect = (e) => {
    if (selectedOrganization && selectedOrganization.id) {
      const payload = {
        organization_id: selectedOrganization.id,
        project_id: selectedProject.value,
      };
      if (e && e.value) {
        payload.task_id = e.value;
        getTaskAssigneeList(payload);
      } else {
        getProjectAssigneeList(payload);
      }
    }
    if (selectTask) {
      selectTask(e);
    }
  };

  const onMemberSelect = (person) => {
    if (selectMember) {
      selectMember(person);
    }
  };

  const clearSelectedMember = () => {
    clearMember();
  };

  return (
    <FilterInnerSection columns={columns || "230px 230px 57px"}>
      <InputWithLabelSection>
        <FilterLabel>Projects</FilterLabel>
        <ProjectDropdown
          selectedOrganization={selectedOrganization}
          selectedProject={selectedProject}
          selectProject={onProjectSelect}
          selectedMember={selectedMember}
          addAllProjects={addAllProjects}
          clearable
          style={FilterDropDownStyle({ height: "40px", width: "180px" })}
        />
      </InputWithLabelSection>

      {!isLocation && (
        <InputWithLabelSection>
          <FilterLabel>Tasks</FilterLabel>
          <TaskDropdown
            selectedOrganization={selectedOrganization}
            selectedProject={selectedProject}
            selectedTask={selectedTask}
            selectTask={onTaskSelect}
            selectedMember={selectedMember}
            style={FilterDropDownStyle({ height: "40px", width: "180px" })}
            addAllTasksOption={addAllTasksOption}
          />
        </InputWithLabelSection>
      )}

      <InputWithLabelSection>
        <FilterLabel>Member</FilterLabel>
        <div style={{ width: `40px` }}>
          <AddSingleMemberDropdown
            assigneeManage={selectedMember}
            updateState={onMemberSelect}
            membersList={memberOptions}
            clearAssignee={() => clearSelectedMember()}
          />
        </div>
      </InputWithLabelSection>
    </FilterInnerSection>
  );
};

export default CommonFilter;
