import { useState, useEffect, useRef } from "react";
import moment from "moment";
import $ from "jquery";

import {
  FlexSection,
  ActivityTextSection,
} from "../../../styledComponents/members";
import {
  CardsContainer,
  CardsContainerScreenRecordings,
  RoundCircle,
} from "../../../styledComponents/Activity";
import { CommonText } from "../../../styledComponents/common";
import NoDataComponent from "../../../components/NoDataComponent/NoDataComponent";

import { timeRangeList } from "./timeRangeList";
import SingleCard from "./SingleCard";

const CardsList = ({
  screenRecordings,
  sortType,
  selectedOrganization,
  selectedMemberId,
  addNotes,
  deleteScreenshot,
  user,
  allNotesIsLoading,
  addNoteIsLoading,
  deleteIsLoading,
  setSelectedVideo,
  cardSize,
  selectedCards,
  setSelectedCards,
  setVideoList,
}) => {
  const [timeRange, setTimeRange] = useState([]);
  const [sortedTimeRange, setSortedTimeRange] = useState([]);

  const ref = useRef(null);
  const [width, setWidth] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.getBoundingClientRect().width);
    }
  }, [screenWidth]);

  useEffect(() => {
    if (screenRecordings && screenRecordings.length > 0) {
      let timeList = JSON.parse(JSON.stringify(timeRangeList));
      const screenshotList = [...screenRecordings];
      screenshotList.map((screenshot) => {
        const taken_hour = screenshot.start_timestamp.hours();
        if (
          timeList[taken_hour] &&
          timeList[taken_hour].id !== null &&
          timeList[taken_hour].id === taken_hour
        ) {
          timeList[taken_hour].screenRecordings.push(screenshot);
        }
      });
      setTimeRange([...timeList]);
    } else if (screenRecordings && screenRecordings.length === 0) {
      setTimeRange([]);
    }
  }, [screenRecordings]);

  useEffect(() => {
    if (sortType.value === "NewToOld") {
      if (screenRecordings && screenRecordings.length > 0) {
        const timeList = JSON.parse(
          JSON.stringify(timeRangeList.slice().reverse())
        );
        const screenshotList = [...screenRecordings];
        screenshotList.map((screenshot) => {
          const taken_hour = screenshot.start_timestamp.hours();
          if (
            timeList[23 - taken_hour] &&
            timeList[23 - taken_hour].id !== null &&
            timeList[23 - taken_hour].id === taken_hour
          ) {
            timeList[23 - taken_hour].screenRecordings.push(screenshot);
          }
        });
        setSortedTimeRange([...timeList]);
      } else if (screenRecordings && screenRecordings.length === 0) {
        setSortedTimeRange([]);
      }
    }
  }, [sortType]);
  useEffect(() => {
    const tmpVideoList = [];
    if (sortType && sortType.value === "OldToNew") {
      const filteredList = timeRange.filter(
        (time) => time.screenRecordings.length > 0
      );
      filteredList.forEach((time) => {
        time.screenRecordings.forEach((item) => {
          item.index = tmpVideoList.length;
          if (item.thumbnails.length > 0) {
            item.thumbnails.forEach((thumbnail) => {
              tmpVideoList.push({ ...item, thumbnail });
            });
          } else {
            tmpVideoList.push({ ...item, thumbnail: null });
          }
        });
      });
    } else {
      const filteredList = sortedTimeRange.filter(
        (time) => time.screenRecordings.length > 0
      );
      filteredList.forEach((time) => {
        time.screenRecordings.forEach((item) => {
          item.index = tmpVideoList.length;
          if (item.thumbnails.length > 0) {
            item.thumbnails.forEach((thumbnail) => {
              tmpVideoList.push({ ...item, thumbnail });
            });
          } else {
            tmpVideoList.push({ ...item, thumbnail: null });
          }
        });
      });
    }
    setVideoList(tmpVideoList);
  }, [sortType, timeRange, sortedTimeRange]);

  return (
    <div style={{ padding: `20px 0`, maxWidth: "100%" }}>
      <CardsContainer>
        <div
          ref={ref}
          style={{
            width: "100%",
            height: "0",
          }}
        ></div>
      </CardsContainer>
      {sortType && sortType.value === "OldToNew" ? (
        <>
          {timeRange && timeRange.length > 0 && (
            <>
              {timeRange.map(
                (time, index) =>
                  time.screenRecordings &&
                  time.screenRecordings.length > 0 && (
                    <ActivityTextSection
                      style={{ marginBottom: `20px`, maxWidth: "100%" }}
                      key={index}
                    >
                      <FlexSection alignItems="center" gap="12px">
                        <RoundCircle />
                        <CommonText title>
                          {`${moment(time.startTimeRange, "H:mm").format(
                            "h:mm A"
                          )} - ${moment(time.endTimeRange, "H:mm").format(
                            "h:mm A"
                          )} `}
                        </CommonText>
                      </FlexSection>

                      <CardsContainerScreenRecordings
                        style={{ maxWidth: "100%" }}
                      >
                        {time.screenRecordings.map((item, i) => (
                          <SingleCard
                            screenshot={item}
                            key={i}
                            selectedOrganization={selectedOrganization}
                            selectedMemberId={selectedMemberId}
                            addNotes={addNotes}
                            deleteScreenshot={deleteScreenshot}
                            user={user}
                            allNotesIsLoading={allNotesIsLoading}
                            addNoteIsLoading={addNoteIsLoading}
                            deleteIsLoading={deleteIsLoading}
                            setSelectedVideo={() => {
                              setSelectedVideo(item.index);
                            }}
                            cardSize={cardSize}
                            selectedCards={selectedCards}
                            setSelectedCards={setSelectedCards}
                            initialCardSize={width}
                          />
                        ))}
                      </CardsContainerScreenRecordings>
                    </ActivityTextSection>
                  )
              )}
            </>
          )}
          {timeRange && timeRange.length === 0 && (
            <NoDataComponent title="No screen records found." />
          )}
        </>
      ) : (
        <>
          {sortedTimeRange && sortedTimeRange.length > 0 && (
            <>
              {sortedTimeRange.map(
                (time, index) =>
                  time.screenRecordings &&
                  time.screenRecordings.length > 0 && (
                    <ActivityTextSection
                      style={{ marginBottom: `20px` }}
                      key={index}
                    >
                      <FlexSection alignItems="center" gap="12px">
                        <RoundCircle />
                        <CommonText title>
                          {`${moment(time.startTimeRange, "H:mm").format(
                            "h:mm A"
                          )} - ${moment(time.endTimeRange, "H:mm").format(
                            "h:mm A"
                          )} `}
                        </CommonText>
                      </FlexSection>
                      <CardsContainerScreenRecordings>
                        {time.screenRecordings.map((item, i) => (
                          <SingleCard
                            screenshot={item}
                            key={i}
                            selectedOrganization={selectedOrganization}
                            selectedMemberId={selectedMemberId}
                            addNotes={addNotes}
                            deleteScreenshot={deleteScreenshot}
                            user={user}
                            allNotesIsLoading={allNotesIsLoading}
                            addNoteIsLoading={addNoteIsLoading}
                            deleteIsLoading={deleteIsLoading}
                            setSelectedVideo={() => {
                              setSelectedVideo(item.index);
                            }}
                            cardSize={cardSize}
                            selectedCards={selectedCards}
                            setSelectedCards={setSelectedCards}
                            initialCardSize={width}
                          />
                        ))}
                      </CardsContainerScreenRecordings>
                    </ActivityTextSection>
                  )
              )}
            </>
          )}
          {sortedTimeRange && sortedTimeRange.length === 0 && (
            <NoDataComponent title="No screen records found." />
          )}
        </>
      )}
    </div>
  );
};

export default CardsList;
