import { useState, useEffect } from "react";

import ComponentCircleLoader from "../../../components/Loaders/ComponentCircleLoader";
import SettingsDisabled from "../../../components/SettingsDisabled/SettingsDisabled";
import CheckBox from "../../../components/CheckBox/checkBox";

import ActivityFilter from "../activityFilterContainer";
import UserActivityInfo from "./UserActivityInfo";
import CardsList from "./CardsList";
import { ShowNotesModal } from "./ScreenRecordingsModals";
import VideoModal from "./VideoModal";

import {
  PageTitle,
  CommonGrid,
  CommonFlex,
  CommonText,
} from "../../../styledComponents/common";
import RecordingsToolbar from "./recordingsToolbar";
import RecordingDownloadModal from "./recordingDownloadModal";

const ActivityScreenRecordings = ({
  liveMembersList,
  getLiveMembersList,
  selectedOrganization,
  allNotes,
  allNotesIsLoading,
  userActivity,
  isLoading,
  screenRecordings,
  clockInOutList,
  addNotes,
  deleteScreenshot,
  addNoteIsLoading,
  deleteIsLoading,
  getVideoLink,
  videoLink,
  downloadVideo,
}) => {
  const [fetchAllNotes, setFetchAllNotes] = useState(false);
  const [isAllNotesModalOpen, setIsAllNotesModalOpen] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [sortType, setSortType] = useState({
    label: "Time (Old to new)",
    value: "OldToNew",
  });
  const sortOptions = [
    { label: "Time (Old to new)", value: "OldToNew" },
    { label: "Time (New to old)", value: "NewToOld" },
  ];
  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const [isHovered, setIsHovered] = useState(false);
  const [selectedCards, setSelectedCards] = useState({});
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const [isRecordingDownloadModalOpen, setIsRecordingDownloadModalOpen] =
    useState(false);

  const [cardSize, setCardSize] = useState(1);

  const toggleRecordingDownloadModal = () => {
    setIsRecordingDownloadModalOpen(!isRecordingDownloadModalOpen);
  };

  const unselectAllCards = () => {
    const tmpSelectedCards = {};
    Object.keys(selectedCards).forEach((key) => {
      tmpSelectedCards[key] = false;
    });
    setSelectedCards(tmpSelectedCards);
  };

  const selectAllCards = () => {
    const selectedCards = {};
    screenRecordings.forEach((screenRecording) => {
      selectedCards[screenRecording.id] = true;
    });
    setSelectedCards(selectedCards);
  };
  useEffect(() => {
    if (selectedVideo != null && isVideoModalOpen !== true) {
      setIsVideoModalOpen(true);
    }
  }, [selectedVideo]);

  useEffect(() => {
    if (selectedOrganization?.id) {
      if (selectedOrganization.timezone) {
        setSelectedTimezone({
          type: "Organization",
          timezone: selectedOrganization.timezone,
          offset: selectedOrganization.timezoneoffset,
        });
      }
    }
  }, [selectedOrganization]);

  useEffect(() => {}, [allNotes]);
  const changeSortType = (type) => {
    setSortType(type);
  };
  const handleAllNotesClick = () => {
    setFetchAllNotes(true);
    setIsAllNotesModalOpen(true);
  };
  const toggleNotesModal = () => {
    setIsAllNotesModalOpen(!isAllNotesModalOpen);
  };

  useEffect(() => {
    if (screenRecordings?.length > 0) {
      const tmpSelectedCards = {};
      const tmpVideoList = [];
      screenRecordings.forEach((screenRecording) => {
        screenRecording.index = tmpVideoList.length;
        if (screenRecording.thumbnails.length > 0) {
          screenRecording.thumbnails.forEach((thumbnail, index) => {
            tmpVideoList.push({
              ...screenRecording,
              thumbnail: thumbnail,
            });
          });
        } else {
          tmpVideoList.push({ ...screenRecording, thumbnail: null });
        }
        tmpSelectedCards[screenRecording.id] = false;
      });
      setVideoList(tmpVideoList);
      setSelectedCards(tmpSelectedCards);
    } else {
      setVideoList([]);
      setSelectedCards({});
    }
  }, [screenRecordings]);

  const handleVideoModalClose = () => {
    setIsVideoModalOpen(false);
    setTimeout(() => {
      setSelectedVideo(null);
    }, 500);
  };

  const downloadSelected = () => {
    const selectedVideoIds = [];

    screenRecordings.forEach((screenRecording) => {
      if (selectedCards[screenRecording.id]) {
        screenRecording.thumbnails.forEach((thumbnail) => {
          selectedVideoIds.push(thumbnail.id);
        });
      }
    });

    if (selectedVideoIds.length > 0) {
      downloadVideo({
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        user_id: userActivity.user.id,
        video_ids: selectedVideoIds,
      });
    }
    unselectAllCards();
  };

  const downloadAll = () => {
    const allVideoIds = [];
    screenRecordings.forEach((screenRecording) => {
      screenRecording.thumbnails.forEach((thumbnail) => {
        allVideoIds.push(thumbnail.id);
      });
    });
    if (allVideoIds.length > 0) {
      downloadVideo({
        organization_id: selectedOrganization.id,
        date: selectedDate.format("YYYY-MM-DD"),
        user_id: userActivity.user.id,
        video_ids: allVideoIds,
      });
    }
    unselectAllCards();
  };

  const deleteSelected = () => {
    console.log("deleteSelected");
  };

  return (
    <div className="content">
      <ShowNotesModal
        isOpen={isAllNotesModalOpen}
        toggle={toggleNotesModal}
        notes={allNotes}
        notesType="all"
        user={userActivity && userActivity.user}
        isLoading={allNotesIsLoading}
      />
      <RecordingDownloadModal
        isOpen={isRecordingDownloadModalOpen}
        toggle={toggleRecordingDownloadModal}
        downloadSelected={downloadSelected}
        downloadAll={downloadAll}
      />
      <VideoModal
        videos={videoList}
        selectedIndex={selectedVideo}
        handleClose={() => {
          handleVideoModalClose();
        }}
        handleChange={(index) => {
          setSelectedVideo(index);
        }}
        isOpen={isVideoModalOpen}
        getVideoLink={getVideoLink}
        selectedOrganization={selectedOrganization}
        videoLink={videoLink}
      />
      <CommonGrid alignItem="center">
        <CommonFlex gap="5px">
          <PageTitle>Screen Records</PageTitle>
        </CommonFlex>
      </CommonGrid>
      <RecordingsToolbar
        selectedCount={Object.values(selectedCards).filter((val) => val).length}
        selectAll={selectAllCards}
        unselectAll={unselectAllCards}
        downloadSelected={downloadSelected}
        downloadAll={downloadAll}
        deleteSelected={deleteSelected}
        allSelected={Object.values(selectedCards).every((val) => val)}
      />
      <ActivityFilter
        title="ScreenCasts"
        sortType={sortType}
        changeSortType={changeSortType}
        fetchAllNotes={fetchAllNotes}
        setFetchAllNotes={setFetchAllNotes}
        setScreenshotMemberId={setSelectedMemberId}
        getLiveMembersList={getLiveMembersList}
        sortOptions={sortOptions}
        toggleRecordingDownloadModal={toggleRecordingDownloadModal}
        setDate={setSelectedDate}
        handleAllNotesClick={handleAllNotesClick}
      />
      {isLoading ? (
        <ComponentCircleLoader />
      ) : (
        selectedOrganization?.configuration && (
          <>
            {screenRecordings?.length > 0 ? (
              <UserActivityInfo
                userActivity={userActivity}
                selectedOrganization={selectedOrganization}
                clockInOutList={clockInOutList}
                liveMembersList={liveMembersList}
              />
            ) : null}

            <div
              style={{
                position: "relative",
              }}
            >
              {" "}
              {screenRecordings?.length > 0 && (
                <CommonFlex
                  style={{
                    position: "absolute",
                    right: `0px`,
                  }}
                >
                  <div style={{ padding: `20px 0` }}>
                    <input
                      type="range"
                      id="sizeSlider"
                      name="sizeSlider"
                      min="1"
                      max="100"
                      value={cardSize}
                      className="custom-slider"
                      style={{
                        background: `linear-gradient(to right, #73cac0 ${cardSize}%, #B1B1B166 ${cardSize}%)`,
                      }}
                      onChange={(e) => setCardSize(e.target.value)}
                    />
                  </div>
                  <CommonFlex
                    gap="10px"
                    onClick={() => {
                      if (
                        Object.values(selectedCards).every((val) => val) ===
                        true
                      ) {
                        unselectAllCards();
                      } else {
                        selectAllCards();
                      }
                    }}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <CheckBox
                      checked={Object.values(selectedCards).every((val) => val)}
                    />
                    <CommonText>Select All</CommonText>
                  </CommonFlex>
                </CommonFlex>
              )}
              {selectedOrganization.configuration.screenshot_track ? (
                <CardsList
                  selectedOrganization={selectedOrganization}
                  selectedMemberId={selectedMemberId}
                  user={userActivity && userActivity.user}
                  screenRecordings={screenRecordings}
                  screenshotBlur={
                    selectedOrganization &&
                    selectedOrganization.configuration &&
                    selectedOrganization.configuration.screenshot_blur
                  }
                  sortType={sortType}
                  addNotes={addNotes}
                  deleteScreenshot={deleteScreenshot}
                  allNotesIsLoading={allNotesIsLoading}
                  addNoteIsLoading={addNoteIsLoading}
                  deleteIsLoading={deleteIsLoading}
                  setSelectedVideo={setSelectedVideo}
                  cardSize={cardSize}
                  selectedCards={selectedCards}
                  setSelectedCards={setSelectedCards}
                  setVideoList={setVideoList}
                />
              ) : (
                <SettingsDisabled feature={`'Screen Recording'`} />
              )}
            </div>
          </>
        )
      )}
    </div>
  );
};

export default ActivityScreenRecordings;
