import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_MULTIPLE_NOTIFICATIONS,
  DELETE_MULTIPLE_NOTIFICATIONS_SUCCESS,
  DELETE_MULTIPLE_NOTIFICATIONS_FAILURE,
  MARK_MULTIPLE_AS_READ,
  MARK_MULTIPLE_AS_READ_SUCCESS,
  MARK_MULTIPLE_AS_READ_FAILURE,
  MARK_ALL_AS_READ,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_ALL_NOTIFICATIONS_SUCCESS,
  CLEAR_ALL_NOTIFICATIONS_FAILURE,
} from "../../modules/constants";
import { createNotification } from "../../modules/notificationManager";

const initialState = {
  isLoading: false,
  notifications: [],
};

// ------------------------------------
// Action handler methods
// ------------------------------------

export const notificationsListRequestHandler = (state, action) => {
  return {
    ...state,
    isLoading: true,
  };
};

export const notificationsListRequestSuccessHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    notifications: action.payload,
  };
};

export const notificationsListRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isLoading: false,
    notifications: [],
  };
};

export const deleteNotificationRequestHandler = (state, action) => {
  return {
    ...state,
    isDeleteNotificationLoading: true,
    oldNotification: [...state.notifications],
    notifications: [
      ...state.notifications.filter(
        (notification) => notification.id !== action.payload?.id
      ),
    ],
  };
};

export const deleteNotificationRequestSuccessHandler = (state, action) => {
  return {
    ...state,
    isDeleteNotificationLoading: false,
    oldNotification: [],
  };
};

export const deleteNotificationRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isDeleteNotificationLoading: false,
    notifications: [...state.oldNotification],
    oldNotification: [],
  };
};

// Action Handlers

export const deleteMultipleNotificationsRequestHandler = (state, action) => {
  return {
    ...state,
    isDeleteNotificationLoading: true,
  };
};

export const deleteMultipleNotificationsRequestSuccessHandler = (
  state,
  action
) => {
  return {
    ...state,
    isDeleteNotificationLoading: false,
    notifications: state.notifications.filter(
      (notification) => !action.payload.ids.includes(notification.id)
    ),
  };
};

export const deleteMultipleNotificationsRequestFailureHandler = (state) => {
  return {
    ...state,
    isDeleteNotificationLoading: false,
  };
};

export const markMultipleAsReadRequestHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: true,
  };
};

export const markMultipleAsReadRequestSuccessHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: false,
    notifications: state.notifications.map((notification) =>
      action.payload.ids.includes(notification.id)
        ? { ...notification, read: true }
        : notification
    ),
  };
};

export const markMultipleAsReadRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: false,
  };
};

export const markAllAsReadRequestHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: true,
  };
};

export const markAllAsReadRequestSuccessHandler = (state, action) => {
  createNotification("success", "Marked as read.", 2000);
  return {
    ...state,
    isMarkAsReadLoading: false,
    notifications: state.notifications.map((notification) => ({
      ...notification,
      read: true,
    })),
  };
};

export const markAllAsReadRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: false,
  };
};

export const markAsReadRequestHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: true,
  };
};

export const markAsReadRequestSuccessHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: false,
    notifications: state.notifications.map((notification) =>
      notification.id === action.payload.id
        ? { ...notification, read: true }
        : notification
    ),
  };
};

export const markAsReadRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isMarkAsReadLoading: false,
  };
};

export const clearAllNotificationsRequestHandler = (state, action) => {
  return {
    ...state,
    isClearNotificationsLoading: true,
  };
};

export const clearAllNotificationsRequestSuccessHandler = (state, action) => {
  createNotification("success", "All Cleared.", 2000);
  return {
    ...state,
    isClearNotificationsLoading: false,
    notifications: [],
  };
};

export const clearAllNotificationsRequestFailureHandler = (state, action) => {
  return {
    ...state,
    isClearNotificationsLoading: false,
  };
};

const ACTION_HANDLERS = {
  [GET_NOTIFICATIONS]: notificationsListRequestHandler,
  [GET_NOTIFICATIONS_SUCCESS]: notificationsListRequestSuccessHandler,
  [GET_NOTIFICATIONS_FAILURE]: notificationsListRequestFailureHandler,

  [DELETE_NOTIFICATION]: deleteNotificationRequestHandler,
  [DELETE_NOTIFICATION_SUCCESS]: deleteNotificationRequestSuccessHandler,
  [DELETE_NOTIFICATION_FAILURE]: deleteNotificationRequestFailureHandler,

  [DELETE_MULTIPLE_NOTIFICATIONS]: deleteMultipleNotificationsRequestHandler,
  [DELETE_MULTIPLE_NOTIFICATIONS_SUCCESS]:
    deleteMultipleNotificationsRequestSuccessHandler,
  [DELETE_MULTIPLE_NOTIFICATIONS_FAILURE]:
    deleteMultipleNotificationsRequestFailureHandler,

  [MARK_MULTIPLE_AS_READ]: markMultipleAsReadRequestHandler,
  [MARK_MULTIPLE_AS_READ_SUCCESS]: markMultipleAsReadRequestSuccessHandler,
  [MARK_MULTIPLE_AS_READ_FAILURE]: markMultipleAsReadRequestFailureHandler,

  [MARK_ALL_AS_READ]: markAllAsReadRequestHandler,
  [MARK_ALL_AS_READ_SUCCESS]: markAllAsReadRequestSuccessHandler,
  [MARK_ALL_AS_READ_FAILURE]: markAllAsReadRequestFailureHandler,

  [MARK_AS_READ]: markAsReadRequestHandler,
  [MARK_AS_READ_SUCCESS]: markAsReadRequestSuccessHandler,
  [MARK_AS_READ_FAILURE]: markAsReadRequestFailureHandler,

  [CLEAR_ALL_NOTIFICATIONS]: clearAllNotificationsRequestHandler,
  [CLEAR_ALL_NOTIFICATIONS_SUCCESS]: clearAllNotificationsRequestSuccessHandler,
  [CLEAR_ALL_NOTIFICATIONS_FAILURE]: clearAllNotificationsRequestFailureHandler,
};

export default function notificationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}
