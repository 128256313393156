import React from "react";
import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import crossIcon from "../../../assets/img/icons/cross_black.svg";
import MemberInvitationCSVComponent from "./memberInvitationCSVComponent";

const ModalCrossIcon = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  right: 15px;
  top: 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
    transition: transform 0.15s ease;
  }
`;

const MemberInvitationCsvModal = ({ isOpen, toggle, emails, setEmails }) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ maxWidth: `max-content`, margin: `0 auto`, minWidth: `510px` }} //
      centered
    >
      <ModalBody style={{ padding: `0`, position: "relative" }}>
        <ModalCrossIcon src={crossIcon} alt="" onClick={toggle} />
        <MemberInvitationCSVComponent {...{ emails, setEmails, toggle }} />
      </ModalBody>
    </Modal>
  );
};

export default MemberInvitationCsvModal;
