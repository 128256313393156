import { useEffect, useState } from "react";
import DownloadIcon from "../../../assets/img/icons/download.svg";
import deleteIcon from "../../../assets/img/icons/delete.svg";
import selectedIcon from "../../../assets/img/icons/selected.svg";
import CrossIcon from "../../../assets/img/icons/big-cross-icon.svg";
import { CommonImage } from "../../../styledComponents/common";
import CheckBox from "../../../components/CheckBox/checkBox";
import {
  ToolbarContainer,
  ToolbarContent,
  SelectedCountContainer,
  UnselectAllButton,
  SelectAllContainer,
  ToolbarButton,
} from "./screenRecordingStyles";

const RecordingsToolbar = ({
  selectedCount = 0,
  selectAll,
  unselectAll,
  downloadSelected,
  downloadAll,
  deleteSelected,
  allSelected,
}) => {
  const [showEaseOut, setShowEaseOut] = useState(false);
  useEffect(() => {
    if (selectedCount > 0) {
      setShowEaseOut(true);
    } else {
      setTimeout(() => setShowEaseOut(false), 500);
    }
  }, [selectedCount]);
  if (selectedCount === 0 && !showEaseOut) {
    return null;
  }
  return (
    <ToolbarContainer>
      <ToolbarContent selected={selectedCount === 0}>
        <SelectedCountContainer>
          <span>{selectedCount} selected</span>
          <UnselectAllButton onClick={unselectAll} size="14px">
            <CommonImage src={CrossIcon} size="8px" />
          </UnselectAllButton>
        </SelectedCountContainer>

        <SelectAllContainer onClick={selectAll}>
          <CheckBox
            checked={allSelected}
            size="18px"
            checkSize="10px"
            useGrey={true}
          />
          <span>Select all</span>
        </SelectAllContainer>

        <ToolbarButton onClick={downloadSelected}>
          <CommonImage src={selectedIcon} size="18px" />
          <span>Download selected</span>
        </ToolbarButton>

        <ToolbarButton onClick={downloadAll}>
          <CommonImage src={DownloadIcon} size="18px" />
          <span>Download all</span>
        </ToolbarButton>

        <ToolbarButton onClick={deleteSelected} color="red">
          <CommonImage src={deleteIcon} size="18px" />
          <span>Delete</span>
        </ToolbarButton>
      </ToolbarContent>
    </ToolbarContainer>
  );
};

export default RecordingsToolbar;
