import moment from "moment";
import React, { useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import noScreenRecords from "../../../assets/img/no-screen-records.png";

import {
  CommonFlex,
  CommonGrid,
  CommonText,
} from "../../../styledComponents/common";
import VideoPlayer from "../../../components/VideoPlayer/VideoPlayer";
import Loader from "react-loader-spinner";

const VideoModal = ({
  videos,
  selectedIndex,
  handleClose,
  handleChange,
  isOpen,
  getVideoLink,
  selectedOrganization,
  videoLink,
}) => {
  const handleNext = () => {
    handleChange((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrevious = () => {
    handleChange(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };

  useEffect(() => {
    if (videos[selectedIndex]?.thumbnail) {
      selectedOrganization?.id &&
        getVideoLink({
          video_id: videos[selectedIndex].thumbnail.id,
          organization_id: selectedOrganization.id,
        });
    }
  }, [selectedIndex, videos, getVideoLink]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      style={{
        height: "100vh",
        width: "100vw",
        maxWidth: "80vw",
        minWidth: "100vw",
        minHeight: "100vh",
        margin: "0",
        padding: "0",
        zIndex: "100",
        overflow: "hidden",
      }}
      centered
      className="video-modal"
    >
      <div
        className="modal-content"
        style={{
          height: "100vh",
          width: "100vw",
          margin: "0",
          top: "0",
          borderRadius: "0",
        }}
      >
        <div className="modal-header">
          <CommonGrid
            columns="1fr 1fr 1fr"
            gap="10px"
            alignItem="start"
            alignContent="start"
            style={{
              textAlign: "left",
            }}
          >
            <CommonText fontSize="13px" name margin="0">
              Project :{" "}
              {videos[selectedIndex]?.project
                ? videos[selectedIndex]?.project.name
                : "No project"}
            </CommonText>
            <CommonText fontSize="13px" name margin="0">
              Task :{" "}
              {(videos[selectedIndex]?.task &&
                videos[selectedIndex]?.task.name) ||
                "No task"}
            </CommonText>
            <CommonText fontSize="13px" name margin="0">
              Activity Level :{" "}
              {videos[selectedIndex]?.activity?.activity || "No activity level"}
              {videos[selectedIndex]?.activity?.activity > 0 && " %"}
            </CommonText>
            <CommonText fontSize="13px" name margin="0">
              {videos[selectedIndex]?.thumbnail?.screen_number && (
                <>
                  Screen :{videos[selectedIndex]?.thumbnail?.screen_number} of{" "}
                  {videos[selectedIndex]?.activity?.screen_count}
                </>
              )}
            </CommonText>

            <CommonText fontSize="13px" name margin="0">
              Time Range :{" "}
              {videos[selectedIndex]?.start_timestamp
                ? `${moment(videos[selectedIndex]?.start_timestamp).format(
                    "hh:mm A"
                  )} -
                  ${moment(videos[selectedIndex]?.end_timestamp).format(
                    "hh:mm A"
                  )}`
                : "No time range"}
            </CommonText>

            <CommonText fontSize="13px" name margin="0">
              Screen Recorded At :{" "}
              {videos[selectedIndex]?.thumbnail?.start_timestamp
                ? moment(
                    videos[selectedIndex]?.thumbnail?.start_timestamp
                  ).format("hh:mm A")
                : "No Screen Records"}
            </CommonText>
          </CommonGrid>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody
          style={{
            height: "calc(100vh - 200px)",
            maxheight: "calc(100vh - 200px)",
          }}
        >
          {videoLink &&
          videos[selectedIndex] &&
          videoLink?.id === videos[selectedIndex]?.thumbnail?.id ? (
            <VideoPlayer video={videoLink?.video_url} onEnded={handleNext} />
          ) : (
            <CommonFlex
              justifyContent="center"
              style={{
                position: "relative",
              }}
            >
              <img
                src={
                  videos[selectedIndex]?.thumbnail?.thumbnail
                    ? videos[selectedIndex].thumbnail.thumbnail
                    : noScreenRecords
                }
                alt="Video Thumbnail"
                style={{
                  width: "auto",
                  height: "70vh",
                  margin: "0 auto",
                  maxheight: "70vh",
                }}
              />
              {videos[selectedIndex]?.thumbnail?.thumbnail && (
                <Loader
                  type="Oval"
                  color="#20bead"
                  height={30}
                  width={30}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
            </CommonFlex>
          )}
          <hr />
          <CommonFlex
            gap="10px"
            style={{
              justifyContent: "space-between",
              padding: "10px 20px",
            }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handlePrevious}
            >
              Previous
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleNext}
            >
              Next
            </button>
          </CommonFlex>
        </ModalBody>
      </div>
    </Modal>
  );
};

export default VideoModal;
