import { useState, useEffect } from "react";
import queryString from "query-string";

import PaginatedDropdown from "../ReactSelectPaginatedDropdown";

import FilterDropDownStyle from "../DropdownStyle/FilterDropDownStyle";

import { defaultApi } from "../../utils/axiosApi";

const TaskDropdown = ({
  selectedOrganization,
  selectedTask,
  selectTask,
  selectedProject,
  selectedMember,
  style,
  addAllTasksOption = false,
  defaultProject,
  preselectedTaskId,
  isManualTimesheet,
}) => {
  const [requestUrl, setRequestUrl] = useState("");

  const getTaskOptions = async (search, prevOptions, page, pageSize, url) => {
    try {
      // console.log(search, prevOptions, page, url)
      if (url) {
        const queryParams = { page };
        if (search.trim()) {
          queryParams.name = search;
        }

        const finalUrl = queryString.stringifyUrl({ url, query: queryParams });

        const response = await defaultApi(finalUrl, "GET");
        const { status, data } = response;

        const options =
          addAllTasksOption && page === 1
            ? [{ value: "", label: "(All Tasks)" }]
            : [];
        let hasMore = false;

        if (status === 200 && data && data.results && data.results.length > 0) {

          // Only show To Do tasks in manual timesheet
          data.results.forEach((item) => {
            if (isManualTimesheet) {
                if (item.status === "to do") {
                    options.push({ value: item.id, label: item.name });
                }
            } else {
                options.push({ value: item.id, label: item.name });
            }
        });
          if (data.next) {
            hasMore = true;
          }
        }

        if (data.results.length === 1 && defaultProject) {
          selectTask(options[0]);
        }

        if (preselectedTaskId) {
          let nData = data;
          let preselectedTask = options.find(
            (option) => option.value === preselectedTaskId
          );
          while (nData.next && !preselectedTask) {
            const response = await defaultApi(nData.next, "GET");
            nData = response.data;
            if (nData.results) {
              nData.results.forEach((item) => {
                options.push({
                  value: item.id,
                  label: item.name,
                });
                if (item.id === preselectedTaskId) {
                  preselectedTask = {
                    value: item.id,
                    label: item.name,
                  };
                }
              });
            }
            hasMore = nData.next ? true : false;
          }
          if (preselectedTask) {
            selectTask(preselectedTask);
          }
        }
        return { options, hasMore };
      }
    } catch (err) {
      // console.log(err);
      return {
        options: [],
        hasMore: false,
      };
    }
  };
  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.id &&
      selectedProject &&
      selectedProject.value
    ) {
      const url = `${selectedOrganization.id}/projects/${selectedProject.value}/tasks/`;
      if (selectedMember && selectedMember.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url,
            query: { members: selectedMember.id },
          })
        );
      } else {
        setRequestUrl(url);
      }
    }
  }, [selectedOrganization, selectedProject]);

  useEffect(() => {
    if (requestUrl) {
      if (selectedMember && selectedMember.id) {
        setRequestUrl(
          queryString.stringifyUrl({
            url: requestUrl,
            query: { members: selectedMember.id },
          })
        );
      } else {
        const parsedUrl = queryString.parseUrl(requestUrl);
        setRequestUrl(parsedUrl.url);
      }
    }
  }, [selectedMember]);
  return (
    <PaginatedDropdown
      isSearchable
      isClearable={selectedTask?.value}
      isDisabled={
        !selectedProject || (selectedProject && !selectedProject.value)
      }
      placeholder="Select Task"
      value={selectedTask}
      url={requestUrl}
      getOptions={getTaskOptions}
      onChange={(e) => selectTask(e)}
      styles={style || FilterDropDownStyle({ minHeight: "40px" })}
    />
  );
};

export default TaskDropdown;
