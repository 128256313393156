import {
  GET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  DELETE_MULTIPLE_NOTIFICATIONS,
  MARK_MULTIPLE_AS_READ,
  MARK_ALL_AS_READ,
  MARK_AS_READ,
  CLEAR_ALL_NOTIFICATIONS,
} from "../../modules/constants";

export const fetchNotifications = (values) => {
  return {
    type: GET_NOTIFICATIONS,
    payload: values,
  };
};

export const deleteNotification = (values) => {
  return {
    type: DELETE_NOTIFICATION,
    payload: values,
  };
};

export const deleteMultipleNotifications = (values) => {
  return {
    type: DELETE_MULTIPLE_NOTIFICATIONS,
    payload: values,
  };
};

export const markMultipleAsRead = (values) => {
  return {
    type: MARK_MULTIPLE_AS_READ,
    payload: values,
  };
};

export const markAllAsRead = (values) => {
  return {
    type: MARK_ALL_AS_READ,
    payload: values,
  };
};

export const markAsRead = (values) => {
  return {
    type: MARK_AS_READ,
    payload: values,
  };
};

export const clearAllNotifications = (values) => {
  return {
    type: CLEAR_ALL_NOTIFICATIONS,
    payload: values,
  };
};
