import { all, call, put, takeLatest } from "redux-saga/effects";
import { defaultApi } from "../../utils/axiosApi";
import {
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  DELETE_NOTIFICATION,
  DELETE_NOTIFICATION_SUCCESS,
  DELETE_NOTIFICATION_FAILURE,
  DELETE_MULTIPLE_NOTIFICATIONS,
  DELETE_MULTIPLE_NOTIFICATIONS_SUCCESS,
  DELETE_MULTIPLE_NOTIFICATIONS_FAILURE,
  MARK_MULTIPLE_AS_READ,
  MARK_MULTIPLE_AS_READ_SUCCESS,
  MARK_MULTIPLE_AS_READ_FAILURE,
  MARK_ALL_AS_READ,
  MARK_ALL_AS_READ_SUCCESS,
  MARK_ALL_AS_READ_FAILURE,
  MARK_AS_READ,
  MARK_AS_READ_SUCCESS,
  MARK_AS_READ_FAILURE,
  CLEAR_ALL_NOTIFICATIONS,
  CLEAR_ALL_NOTIFICATIONS_SUCCESS,
  CLEAR_ALL_NOTIFICATIONS_FAILURE,
} from "../../modules/constants";

function* fetchNotifications({ payload }) {
  const { organizationId } = payload;

  try {
    const url = `${organizationId}/notifications/`;
    const method = "GET";
    const response = yield defaultApi(url, method);
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, payload: response.data });
  } catch (error) {
    yield put({ type: GET_NOTIFICATIONS_FAILURE, payload: error });
  }
}

function* deleteNotification({ payload }) {
  const { id, organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/${id}/`;
    const method = "DELETE";
    yield call(defaultApi, url, method);
    yield put({ type: DELETE_NOTIFICATION_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_NOTIFICATION_FAILURE, payload: error });
  }
}

function* deleteMultipleNotifications({ payload }) {
  const { organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/delete-multiple/`;
    const method = "DELETE";
    yield call(defaultApi, url, method, payload);
    yield put({ type: DELETE_MULTIPLE_NOTIFICATIONS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_MULTIPLE_NOTIFICATIONS_FAILURE, payload: error });
  }
}

function* markMultipleAsRead({ payload }) {
  const { organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/mark-multiple-as-read/`;
    const method = "PATCH";
    yield call(defaultApi, url, method, payload);
    yield put({ type: MARK_MULTIPLE_AS_READ_SUCCESS, payload });
  } catch (error) {
    yield put({ type: MARK_MULTIPLE_AS_READ_FAILURE, payload: error });
  }
}

function* markAllAsRead({ payload }) {
  const { organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/mark-all-as-read/`;
    const method = "PATCH";
    yield call(defaultApi, url, method, payload);
    yield put({ type: MARK_ALL_AS_READ_SUCCESS, payload });
  } catch (error) {
    yield put({ type: MARK_ALL_AS_READ_FAILURE, payload: error });
  }
}

function* markAsRead({ payload }) {
  const { id, organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/${id}/mark-single-as-read/`;
    const method = "PATCH";
    yield call(defaultApi, url, method);
    yield put({ type: MARK_AS_READ_SUCCESS, payload });
  } catch (error) {
    yield put({ type: MARK_AS_READ_FAILURE, payload: error });
  }
}

function* clearAllNotifications({ payload }) {
  const { organizationId } = payload;
  try {
    const url = `${organizationId}/notifications/delete-all/`;
    const method = "DELETE";
    yield call(defaultApi, url, method);
    yield put({ type: CLEAR_ALL_NOTIFICATIONS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: CLEAR_ALL_NOTIFICATIONS_FAILURE, payload: error });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_NOTIFICATIONS, fetchNotifications),
    takeLatest(DELETE_NOTIFICATION, deleteNotification),
    takeLatest(DELETE_MULTIPLE_NOTIFICATIONS, deleteMultipleNotifications),
    takeLatest(MARK_MULTIPLE_AS_READ, markMultipleAsRead),
    takeLatest(MARK_ALL_AS_READ, markAllAsRead),
    takeLatest(MARK_AS_READ, markAsRead),
    takeLatest(CLEAR_ALL_NOTIFICATIONS, clearAllNotifications),
  ]);
}
