import {
  GET_APPS_LIST,
  GET_ALL_NOTES,
  GET_URL_LIST,
  GET_SCREENSHOT_LIST,
  GET_LOCATION_LIST,
  ADD_NOTES,
  DELETE_SCREENSHOT,
  SORT_SCREENSHOTS,
  GET_SCREENSHOT_NOTES,
  GET_USER_ACTIVITY,
  GET_SCREENCAST_LIST,
  GET_VIDEO_LINK,
  DOWNLOAD_VIDEO,
} from "../../modules/constants";

// ------------------------------------
// Actions
// ------------------------------------
export function getAppsList(value) {
  return {
    type: GET_APPS_LIST,
    payload: value,
  };
}

export function sortScreenshots(value) {
  return {
    type: SORT_SCREENSHOTS,
    payload: value,
  };
}

export function getUrlList(value) {
  return {
    type: GET_URL_LIST,
    payload: value,
  };
}

export function getScreenshotList(value) {
  return {
    type: GET_SCREENSHOT_LIST,
    payload: value,
  };
}

export function deleteScreenshot(value) {
  return {
    type: DELETE_SCREENSHOT,
    payload: value,
  };
}

export function getLocationList(value) {
  return {
    type: GET_LOCATION_LIST,
    payload: value,
  };
}

export function getAllNotes(params) {
  return {
    type: GET_ALL_NOTES,
    payload: params,
  };
}

export function allNotesScreenshot(id) {
  return {
    type: GET_SCREENSHOT_NOTES,
    payload: id,
  };
}

export function addNotes(message) {
  return {
    type: ADD_NOTES,
    payload: message,
  };
}

export function getUserActivity(value) {
  return {
    type: GET_USER_ACTIVITY,
    payload: value,
  };
}

export function getScreenCastList(value) {
  return {
    type: GET_SCREENCAST_LIST,
    payload: value,
  };
}

export function getVideoLink(videoId) {
  return {
    type: GET_VIDEO_LINK,
    payload: videoId,
  };
}

export function downloadVideo(params) {
  return {
    type: DOWNLOAD_VIDEO,
    payload: params,
  };
}

export const actions = {
  getAppsList,
  getAllNotes,
  getScreenshotList,
  getUrlList,
  getLocationList,
  addNotes,
  deleteScreenshot,
  sortScreenshots,
  allNotesScreenshot,
  getUserActivity,
  getScreenCastList,
  getVideoLink,
  downloadVideo,
};
