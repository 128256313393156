import { Modal, ModalBody } from "reactstrap";
import styled from "styled-components";

import {
  CommonIconWhButton,
  CommonGrid,
  CommonText,
  CommonImage,
} from "../../../styledComponents/common";

import csv from "../../../assets/img/icons/csv.svg";
import crossIcon from "../../../assets/img/icons/cross_black.svg";
import selectedIcon from "../../../assets/img/icons/selected.svg";
import DownloadIcon from "../../../assets/img/icons/download.svg";

const ModalCrossIcon = styled.img`
  position: absolute;
  height: 25px;
  width: 25px;
  right: 15px;
  top: 15px;
  cursor: pointer;
  &:hover {
    transform: scale(1.15);
    transition: transform 0.15s ease;
  }
`;

const IconButton = styled(CommonIconWhButton)`
  border-color: #e4e7eb;
  padding: 14px 25px;
  grid-column-gap: 13px;
  &:hover {
    border-color: #20bead;
  }
`;

const RecordingDownloadModal = ({
  isOpen,
  toggle,
  downloadSelected,
  downloadAll,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `30vh`, maxWidth: `480px` }}
    >
      <ModalBody style={{ padding: `35px 0 15px 0`, position: "relative" }}>
        <ModalCrossIcon src={crossIcon} alt="" onClick={() => toggle()} />
        <div style={{ padding: `0 30px` }}>
          <CommonText fontSize="18px" fontWeight="600" name="true">
            Download Screen Records
          </CommonText>
          <CommonGrid
            columns="1fr 1fr"
            padding="20px 0 25px 0"
            style={{ marginTop: `30px` }}
          >
            <IconButton
              onClick={() => {
                downloadSelected();
                toggle();
              }}
            >
              <CommonImage src={selectedIcon} />
              <div>Download Selected</div>
            </IconButton>
            <IconButton
              onClick={() => {
                downloadAll();
                toggle();
              }}
            >
              <CommonImage src={DownloadIcon} />

              <div>Download All</div>
            </IconButton>
          </CommonGrid>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RecordingDownloadModal;
