import Papa from "papaparse";
import { useEffect, useRef, useState } from "react";

import ButtonTextLoader from "../../../components/Loaders/ButtonTextLoader";
import {
  ButtonIcon,
  PrimaryButton,
  WhiteButton,
} from "../../../styledComponents/buttons";

import {
  CardTitle,
  CommonFlex,
  CommonText,
  Container,
} from "../../../styledComponents/common";
import {
  BottomLeft,
  BottomRight,
  BottomRow,
  DottedRoundedBorder,
  InvalidEmailContainer,
  ValidEmailContainer,
} from "../../../styledComponents/members";
import { validateEmail } from "../../../utils/helper";

import downloadIcon from "../../../assets/img/icons/download-blue.svg";
import upArrow from "../../../assets/img/icons/up_arrow_blue.svg";

const MemberInvitationCSVComponent = ({
  memberInvited,
  inviteMemberLoading,
  emails,
  setEmails,
  toggle,
}) => {
  const [validEmails, setValidEmails] = useState([]);
  const [invalidEmails, setInvalidEmails] = useState([]);
  const fileInputRef = useRef(null);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [extraRow, setExtraRow] = useState(false);

  const handleFileSelect = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (memberInvited) {
      setValidEmails([]);
      setInvalidEmails([]);
      setFileUploaded(false);
      setErrorMessage("");
      setExtraRow(false);
    }
  }, [memberInvited]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      return;
    }

    const reader = new FileReader();
    reader.onload = (e) => {
      const fileContent = e.target.result;
      setFileUploaded(true);
      setExtraRow(false);

      const fileName = selectedFile.name;
      const extension = fileName.split(".").pop().toLowerCase();
      const fileSizeInMB = selectedFile.size / (1024 * 1024);

      if (extension !== "csv" && extension !== "xlsx") {
        setErrorMessage(
          "Invalid file format. Please select a CSV or XLSX file."
        );
        return;
      }

      if (fileSizeInMB > 2) {
        setErrorMessage("File size must be less than 2MB");
        return;
      }

      Papa.parse(fileContent, {
        header: true,
        skipEmptyLines: true,
        delimiter: ",",
        complete: (result) => {
          if (result.errors.length > 0) {
            setErrorMessage("Error parsing CSV: " + result.errors[0].message);
          } else if (result.data.length === 0) {
            setErrorMessage("No Data in the CSV File");
            setValidEmails([]);
            setInvalidEmails([]);
          } else {
            const emailColumn = Object.keys(result.data[0]).find((header) =>
              header.toLowerCase().includes("email")
            );

            const emailArray = emailColumn
              ? result.data.map((item) =>
                  item && item[emailColumn] ? item[emailColumn].trim() : ""
                )
              : [];

            const validEmailArray = [];
            const invalidEmailArray = [];

            emailArray.forEach((email) => {
              if (validateEmail(email)) {
                validEmailArray.push(email);
              } else if (email !== "") {
                invalidEmailArray.push(email);
              } else if (email === "") {
                setExtraRow(true);
              }
            });
            setValidEmails(validEmailArray);
            setInvalidEmails(invalidEmailArray);

            setErrorMessage("");
          }
        },
        error: (error) => {
          setErrorMessage("Error parsing CSV: " + error.message);
        },
      });
    };
    reader.readAsText(selectedFile);
  };

  const handleDownloadTemplate = () => {
    const csvContent =
      "Email\njohn.doe@example.com\nsarah.smith@example.com\nmark.wilson@example.com\n";

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "template.csv";

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);

    URL.revokeObjectURL(url);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = e.dataTransfer.files;
    const syntheticEvent = { target: { files: droppedFiles } };

    handleFileChange(syntheticEvent);
  };

  const addEmails = () => {
    if (validEmails.length > 0) {
      const newMailList = [...new Set([...emails, ...validEmails])];
      setEmails(newMailList);
      toggle();
    }
  };

  return (
    <>
      <Container style={{ padding: `30px`, border: "none", paddingTop: "0px" }}>
        <CardTitle>Upload CSV file</CardTitle>

        <div>
          {fileUploaded ? (
            validEmails.length === 0 && invalidEmails.length === 0 ? (
              <>
                <InvalidEmailContainer>
                  <CommonText>No email address found</CommonText>
                </InvalidEmailContainer>
                <span
                  style={{
                    color: "#2268EF",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "600",
                    marginTop: "25px",
                  }}
                  onClick={handleFileSelect}
                >
                  <ButtonIcon size="15px" src={upArrow} alt="" />
                  Upload CSV again
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  accept=".csv, .xlsx"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                {errorMessage && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errorMessage}
                  </div>
                )}
              </>
            ) : (
              <>
                <ValidEmailContainer>
                  <CommonText color="#18B54D">
                    {validEmails.length === 0 || validEmails.length === 1
                      ? `${validEmails.length} email address found`
                      : `${validEmails.length} email addresses found`}
                  </CommonText>
                </ValidEmailContainer>
                {errorMessage && (
                  <div style={{ color: "red", margin: "10px 0" }}>
                    {errorMessage}
                  </div>
                )}
                {extraRow && (
                  <CardTitle margin="20px 0 25px 0">
                    Emails of extra columns won't be counted
                  </CardTitle>
                )}
                {invalidEmails && invalidEmails.length > 0 && (
                  <div>
                    <CardTitle fontSize="14px" margin="20px 0 10px 0">
                      Invalid Emails:
                    </CardTitle>

                    {invalidEmails.map((email, index) => (
                      <InvalidEmailContainer key={index}>
                        {email}
                      </InvalidEmailContainer>
                    ))}
                  </div>
                )}
                <CommonFlex justifyContent="space-between">
                  <div
                    style={{
                      width: "max-content",
                    }}
                  >
                    <span
                      style={{
                        color: "#2268EF",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "600",
                        marginTop: "25px",
                      }}
                      onClick={handleFileSelect}
                    >
                      Upload CSV again
                    </span>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>

                  <PrimaryButton
                    disabled={validEmails.length === 0}
                    padding={inviteMemberLoading && "0 25px"}
                    onClick={() => addEmails()}
                  >
                    {inviteMemberLoading ? (
                      <ButtonTextLoader
                        loadingText="Sending Invitation"
                        fontSize="13px"
                      />
                    ) : (
                      "Add Emails"
                    )}
                  </PrimaryButton>
                </CommonFlex>
              </>
            )
          ) : (
            <DottedRoundedBorder
              onDragOver={handleDragOver}
              onDragEnter={handleDragEnter}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
              style={{
                minWidth: "450px",
              }}
            >
              <CommonText margin="20px 0 0 0">
                Drag & drop your CSV file here
              </CommonText>
              <CommonText margin="0px">
                or{" "}
                <span
                  style={{
                    color: "#2268EF",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                  onClick={handleFileSelect}
                >
                  select a file from your computer
                </span>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".csv, .xlsx"
                  onChange={handleFileChange}
                />
              </CommonText>

              <BottomRow>
                <BottomLeft>
                  <CommonText margin="0px 0 0 15px">
                    Required Column:
                  </CommonText>
                  <WhiteButton bgColor="#EFF4F9" margin="10px 0 0 15px">
                    Email
                  </WhiteButton>
                </BottomLeft>
                <BottomRight>
                  <CommonText margin="0px 15px">
                    Template:{" "}
                    <span
                      style={{
                        color: "#2268EF",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontWeight: "600",
                      }}
                      onClick={handleDownloadTemplate}
                    >
                      <ButtonIcon size="15px" src={downloadIcon} alt="" />{" "}
                      template.csv
                    </span>
                  </CommonText>
                </BottomRight>
              </BottomRow>
            </DottedRoundedBorder>
          )}
        </div>
      </Container>
    </>
  );
};

export default MemberInvitationCSVComponent;
