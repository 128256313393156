import { useState } from "react";
import styled from "styled-components";
import { Modal, ModalBody, Button } from "reactstrap";
import { WhiteButton, ModalDeleteButton } from "../../styledComponents/buttons";
import {
  CardTitle,
  CommonText,
  CommonFlex,
} from "../../styledComponents/common";
import { CheckboxDiv, CheckboxTickIcon } from "../../styledComponents/settings";
import tickWhite from "../../assets/img/icons/tick_2.svg";

const CustomModal = styled(Modal)`
  max-width: 600px;
  margin-top: 0;
  margin: ${({ centered }) => (centered ? "auto" : "")};
  @media screen and (min-width: 1400px) {
    margin-top: 10vh;
  }
`;
const CustomModalBody = styled(ModalBody)`
  padding: 30px;
`;
const ModalButtonSection = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  grid-column-gap: 12px;
  margin-top: 25px;
`;

const ClearAllNotificationWarningModal = ({
  isOpen,
  toggle,
  clearAllNotifications,
}) => {
  const handleClearAll = () => {
    clearAllNotifications();
  };

  const handleToggle = () => {
    toggle();
  };

  return (
    <CustomModal
      isOpen={isOpen}
      toggle={handleToggle}
      style={{
        marginTop: "25vh",
      }}
    >
      <CustomModalBody>
        <CardTitle>Clear All?</CardTitle>
        <CommonText margin="20px 0 0">
          You've unread notification(s). Are you you sure you want to clear all?
        </CommonText>
        <ModalButtonSection>
          <WhiteButton type="cancel" onClick={handleToggle}>
            Cancel
          </WhiteButton>
          <ModalDeleteButton type="delete" onClick={handleClearAll}>
            Clear All
          </ModalDeleteButton>
        </ModalButtonSection>
      </CustomModalBody>
    </CustomModal>
  );
};

export default ClearAllNotificationWarningModal;
