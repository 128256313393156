import { useState, useEffect, useRef } from "react";
import { Switch, useHistory, Route, Redirect } from "react-router-dom";

import moment from "moment";

import AdminNavbar from "../../custom_modules/AdminNavbar";
import Footer from "components/Footer/Footer.jsx";
// import Sidebar from "components/Sidebar/Sidebar.jsx";
import Sidebar from "../../custom_modules/Sidebar";

import routes from "routes.js";
import { PrivateRoute } from "../../modules/privateRoute";
import { createNotification } from "../../modules/notificationManager";
import { useDispatch } from "react-redux";
import { addPath } from "../../filterReducer";

const Admin = (props) => {
  const dispatch = useDispatch();
  const [sidebarMini, setSidebarMini] = useState(false);
  const [navOpen, setNavOpen] = useState(false);

  const adminHistory = useHistory();
  const wrapperRef = useRef(null);

  const { selectedOrganization } = props;

  useEffect(() => {
    if (localStorage.getItem("is_owner")) {
      const userEmail = localStorage.getItem("email");
      // initialize crisp chat
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "256df938-dacc-4ac6-bc76-8d8e8493d561";
      (function () {
        var d = document;
        var s = d.createElement("script");
        s.src = "https://client.crisp.chat/l.js";
        s.async = 1;
        d.getElementsByTagName("head")[0].appendChild(s);
      })();
      if (localStorage.getItem("user_fullName")) {
        window.$crisp.push([
          "set",
          "user:nickname",
          localStorage.getItem("user_fullName"),
        ]);
      }
      if (userEmail) {
        window.$crisp.push(["set", "user:email", userEmail]);
      }

      // initailize profitwell retain tag manager
      if (profitwell && userEmail) {
        profitwell("user_email", userEmail);
      }
      // if (window.dataLayer) {
      //   window.dataLayer.push({
      //     event: "start_pw",
      //     ...(userEmail && { pw_user_email: userEmail }),
      //   });
      // }
    }
  }, []);

  useEffect(() => {
    if (
      selectedOrganization &&
      selectedOrganization.plan &&
      selectedOrganization.plan.id
    ) {
      localStorage.setItem(
        "selected_organization_plan",
        selectedOrganization.plan.name
      );
    }
  }, [selectedOrganization]);

  // useEffect(() => {
  //   if (selectedOrganization?.id && props.fetchNotifications) {
  //     props.fetchNotifications({
  //       organizationId: selectedOrganization.id,
  //     });
  //   }
  // }, [selectedOrganization, location.pathname]);

  useEffect(() => {
    return adminHistory.listen((location, action) => {
      if (action && action === "PUSH") {
        if (wrapperRef && wrapperRef.current) {
          document.documentElement.scrollTop = 0;
          document.scrollingElement.scrollTop = 0;
          wrapperRef.current.scrollTop = 0;
        }
      }
    });
  }, [adminHistory]);

  useEffect(() => {
    dispatch(addPath(location.pathname));
  }, [location.pathname, dispatch]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/user") {
        return (
          <PrivateRoute
            path={prop.layout + prop.path}
            restricted={prop.restricted}
            selectedOrganization={selectedOrganization}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      setSidebarMini(false);
    } else {
      setSidebarMini(true);
    }
    document.body.classList.toggle("sidebar-mini");
  };
  const handleNavOpen = () => {
    setNavOpen(!navOpen);
  };
  const clearNavOpen = () => {
    setNavOpen(false);
  };

  return (
    <div className="wrapper" ref={wrapperRef}>
      <Sidebar
        {...props}
        routes={routes}
        handleMiniClick={handleMiniClick}
        sidebarMini={sidebarMini}
        navOpen={navOpen}
        clearNavOpen={clearNavOpen}
      />
      <div className="main-panel">
        <AdminNavbar
          sidebarState={sidebarMini}
          {...props}
          handleMiniClick={handleMiniClick}
          handleNavOpen={handleNavOpen}
        />
        <Switch>
          {getRoutes(routes)}
          <Route path="/user/*">
            {() => {
              createNotification("error", "Page does not exist!");
              return <Redirect to="/user/dashboard" />;
            }}
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Admin;
