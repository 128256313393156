import { memo } from "react";
import { NavLink } from "react-router-dom";

import {
  SingleLinkContainer,
  SingleCollapseLinkContainer,
  CollapseArrowIcon,
  SidebarLinkCollapse,
  SidebarListItem,
  SidebarCollapseList,
  SidebarCollapseListItem,
  SidebarLink,
  LinkIcon,
  LinkText,
  NewBadge,
  CollapseLinkIcon,
  CollapseLinkText,
} from "./sidebarStyles";

import { ROLES, RESTRICTED_ROUTES, ROUTE_MAPPINGS } from "./constants";
import { ICON_MAP } from "./iconMapping";

import arrowDown from "assets/img/Sidebar/sidebar-arrow-down.svg";
import arrowUp from "assets/img/Sidebar/sidebar-arrow-up.svg";

import { PulsatingDot } from "../LiveFeed/CurrentlyTracking/CurrentlyTracking";

export const activeRoute = (routeName, location) => {
  // Check direct match
  if (location.pathname.indexOf(routeName) > -1) return true;

  // Check nested routes
  return ROUTE_MAPPINGS[routeName]?.includes(location.pathname) || false;
};

const isRouteAllowedForRole = (path, organization) => {
  if (!organization) return false;

  const { role, plan } = organization;

  // Basic role checks
  if (role === ROLES.MEMBER && RESTRICTED_ROUTES.MEMBER.includes(path.name)) {
    return false;
  }

  // Team leader checks
  if (
    role === ROLES.TEAM_LEADER &&
    RESTRICTED_ROUTES.TEAM_LEADER.includes(path.name)
  ) {
    return false;
  }

  // Plan checks
  if (
    plan?.name === "Solo" &&
    RESTRICTED_ROUTES.SOLO_PLAN.includes(path.name)
  ) {
    return false;
  }

  return true;
};

const isViewAllowedForConfig = (view, organization) => {
  if (!organization) return false;

  const { plan, configuration } = organization;

  // Check screenshot tracking
  if (plan?.screenshot_track === false && view.monitored) {
    return false;
  }

  return true;
};

const doOrganizationHasAddons = (path, organization) => {
  if (!organization) return false;

  const { subscription } = organization;

  // If video_recording addons is false, exclude the "Screen Records" component
  if (
    subscription &&
    !subscription.addon_plan?.video_recording &&
    path.name === "Screen Records"
  ) {
    return false;
  }
  return true;
};

export const generateGroupedLinks = (routes, organization) => {
  if (!organization) return null;

  const sidebarGroupedLinks = { Analyze: [], Manage: [], Admin: [] };

  routes.forEach((path) => {
    if (
      !path.redirect &&
      isRouteAllowedForRole(path, organization) &&
      isViewAllowedForConfig(path, organization)
    ) {
      sidebarGroupedLinks[path.group]?.push(path);
    }
  });

  return sidebarGroupedLinks;
};

const CollapseLink = memo(({ view, location, sidebarMini }) => (
  <SidebarListItem collapse>
    <NavLink to={view.layout + view.path} activeClassName="">
      <SingleCollapseLinkContainer
        style={{ paddingLeft: "10px" }}
        active={activeRoute(view.layout + view.path, location)}
        sidebarMini={sidebarMini}
      >
        <CollapseLinkIcon>{view.mini}</CollapseLinkIcon>
        <CollapseLinkText>{view.name}</CollapseLinkText>
        {view.new && (
          <NewBadge mini={true}>{view.beta ? "BETA" : "NEW"}</NewBadge>
        )}
      </SingleCollapseLinkContainer>
    </NavLink>
  </SidebarListItem>
));

export const getCollapseLinks = (
  organization,
  views,
  location,
  sidebarMini
) => {
  if (!views?.length) return [];

  return views
    .filter(
      (v) =>
        !v.hide &&
        isViewAllowedForConfig(v, organization) &&
        doOrganizationHasAddons(v, organization)
    )
    .map((view, index) => (
      <CollapseLink
        key={`${view.name}-${index}`}
        view={view}
        location={location}
        sidebarMini={sidebarMini}
      />
    ));
};

export const getLinkView = (
  organization,
  path,
  index,
  collapseState,
  setCollapseState,
  location,
  sidebarMini
) => {
  if (!organization) {
    return null;
  }

  if (path.collapse) {
    let st = {};
    st[path["state"]] = !collapseState[path.state];
    return (
      <SidebarCollapseListItem
        key={index}
        collapse
        active={getCollapseInitialState(path.views)}
      >
        <SidebarLink
          active={getCollapseInitialState(path.views)}
          aria-expanded={collapseState[path.state]}
          onClick={(e) => {
            e.preventDefault();
            setCollapseState(st);
          }}
        >
          {path.icon !== undefined && (
            <SingleLinkContainer sidebarMini={sidebarMini}>
              {path.live ? (
                <PulsatingDot
                  active={getCollapseInitialState(path.views)}
                  size="10px"
                  margin="10px 7px 7px 9px"
                />
              ) : (
                <LinkIcon src={ICON_MAP[path.icon]} alt="" />
              )}

              <LinkText>{path.name}</LinkText>
              {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
              <CollapseArrowIcon
                src={arrowDown}
                alt=""
                collapsed={!collapseState[path.state]}
              />
            </SingleLinkContainer>
          )}
        </SidebarLink>
        <SidebarLinkCollapse isOpen={collapseState[path.state]}>
          <SidebarCollapseList>
            {getCollapseLinks(
              organization,
              path.views,
              location,
              sidebarMini,
              path.collapse
            )}
          </SidebarCollapseList>
        </SidebarLinkCollapse>
      </SidebarCollapseListItem>
    );
  }
  return (
    <SidebarListItem
      key={index}
      active={activeRoute(path.layout + path.path, location)}
      name={path.name}
    >
      <NavLink to={path.layout + path.path} activeClassName="">
        {path.icon !== undefined && (
          <SingleLinkContainer name={path.name} sidebarMini={sidebarMini}>
            {path.live ? (
              <PulsatingDot
                active={activeRoute(path.layout + path.path, location)}
                size="10px"
                margin="10px 7px 7px 9px"
              />
            ) : (
              <LinkIcon src={ICON_MAP[path.icon]} alt="" />
            )}
            <LinkText>{path.name}</LinkText>
            {path.new && <NewBadge>{path.beta ? "BETA" : "NEW"}</NewBadge>}
          </SingleLinkContainer>
        )}
      </NavLink>
    </SidebarListItem>
  );
};

// this verifies if any of the collapses should be default opened on a rerender of this component
// for example, on the refresh of the page,
// while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
export const getCollapseInitialState = (routes) => {
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
      return true;
    } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
      return true;
    }
  }
  return false;
};

// this creates the intial state of this component based on the collapse routes
// that it gets through this.props.routes
export const getCollapsedStates = (routes) => {
  let initialState = {};
  routes.map((path, key) => {
    if (path.collapse) {
      initialState = {
        [path.state]: getCollapseInitialState(path.views),
        ...getCollapsedStates(path.views),
        ...initialState,
      };
    }
    return null;
  });
  return initialState;
};
