import { Modal, ModalBody } from "reactstrap";
import { CommonText } from "../../styledComponents/common";
import { WhiteButton, ColoredButton } from "../../styledComponents/buttons";
import { ModalButtonSection } from "../../styledComponents/invoice";

import ButtonTextLoader from "../../components/Loaders/ButtonTextLoader";
import { useCallback } from "react";

const DeleteInvoicePopup = ({
  isOpen,
  toggle,
  organization_id,
  invoice_id,
  deleteInvoice,
  isLoading,
  currentPageNumber,
}) => {
 
  const debouncedOnDeleteInvoice = useCallback(() => {
    if (!isLoading) {
      const payload = {
        organization_id: organization_id,
        invoice_id: invoice_id,
        currentPageNumber,
      };
      deleteInvoice(payload);
      if (isOpen) toggle()
    }
  }, [isLoading, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      style={{ marginTop: `20vh`, maxWidth: `520px` }}
    >
      <ModalBody style={{ padding: `30px 0 15px` }}>
        <CommonText margin="0 0 0 30px">
          Are you sure you want to delete this invoice?
        </CommonText>
        <ModalButtonSection justifyContent="flex-end">
          <WhiteButton type="cancel" onClick={toggle}>
            Cancel
          </WhiteButton>
          <ColoredButton
            type="delete"
            padding={isLoading ? "0 15px" : "0 30px"}
            onClick={() => debouncedOnDeleteInvoice()}
          >
            {isLoading ? (
              <ButtonTextLoader loadingText="Deleting" fontSize="13px" />
            ) : (
              "Delete"
            )}
          </ColoredButton>
        </ModalButtonSection>
      </ModalBody>
    </Modal>
  );
};

export default DeleteInvoicePopup;
