//Effects
import { all, put, takeLatest } from "redux-saga/effects";

//Service
import { defaultApi } from "../../utils/axiosApi";

//Constants
import {
  GET_APPS_LIST,
  GET_APPS_LIST_SUCCESS,
  GET_APPS_LIST_FAILURE,
  GET_URL_LIST,
  GET_URL_LIST_SUCCESS,
  GET_URL_LIST_FAILURE,
  GET_SCREENSHOT_LIST,
  GET_SCREENSHOT_LIST_SUCCESS,
  GET_SCREENSHOT_LIST_FAILURE,
  GET_LOCATION_LIST,
  GET_LOCATION_LIST_SUCCESS,
  GET_LOCATION_LIST_FAILURE,
  GET_ALL_NOTES,
  GET_ALL_NOTES_SUCCESS,
  GET_ALL_NOTES_FAILURE,
  GET_SCREENSHOT_NOTES,
  GET_SCREENSHOT_NOTES_SUCCESS,
  GET_SCREENSHOT_NOTES_FAILURE,
  DELETE_SCREENSHOT,
  DELETE_SCREENSHOT_SUCCESS,
  DELETE_SCREENSHOT_FAILURE,
  SORT_SCREENSHOTS,
  SORT_SCREENSHOTS_SUCCESS,
  SORT_SCREENSHOTS_FAILURE,
  ADD_NOTES,
  ADD_NOTES_SUCCESS,
  ADD_NOTES_FAILURE,
  GET_USER_ACTIVITY,
  GET_USER_ACTIVITY_SUCCESS,
  GET_USER_ACTIVITY_FAILURE,
  GET_SCREENCAST_LIST,
  GET_SCREENCAST_LIST_SUCCESS,
  GET_SCREENCAST_LIST_FAILURE,
  GET_VIDEO_LINK,
  GET_VIDEO_LINK_SUCCESS,
  GET_VIDEO_LINK_FAILURE,
  DOWNLOAD_VIDEO,
  DOWNLOAD_VIDEO_SUCCESS,
  DOWNLOAD_VIDEO_FAILURE,
} from "../../modules/constants";

export function* getAppsList({ payload }) {
  if (!payload) return;
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/app-activity/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_APPS_LIST_SUCCESS,
      payload: { data: response, timezone },
    });
  } catch (err) {
    yield put({
      type: GET_APPS_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getUrlList({ payload }) {
  if (!payload) return;
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/url-activity/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_URL_LIST_SUCCESS,
      payload: { data: response, timezone },
    });
  } catch (err) {
    yield put({
      type: GET_URL_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getScreenshotList({ payload }) {
  if (!payload) return;
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/timeframes/activity-with-screenshot/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_SCREENSHOT_LIST_SUCCESS,
      payload: { data: response, timezone },
    });
  } catch (err) {
    yield put({
      type: GET_SCREENSHOT_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getScreenCastList({ payload }) {
  if (!payload) return;
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/timeframes/activity-with-video-thumbnail/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_SCREENCAST_LIST_SUCCESS,
      payload: {
        data: response,
        timezone,
      },
    });
  } catch (err) {
    yield put({
      type: GET_SCREENCAST_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getLocationtList({ payload }) {
  if (!payload) return;
  try {
    let url = `location_activities/`;

    const response = yield defaultApi(url, "POST", payload);
    yield put({
      type: GET_LOCATION_LIST_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_LOCATION_LIST_FAILURE,
      payload: err,
    });
  }
}

export function* getAllNotes({ payload }) {
  if (!payload) return;
  const { organization_id, date, user_id } = payload;
  try {
    // let url = `${organization_id}/timesheets/timeframes/activity/all-notes/?date=${date}&user_id=${user_id}`;
    const url = `${organization_id}/reports/notes/?date=${date}&user_id=${user_id}`;
    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_ALL_NOTES_SUCCESS,
      payload: { data: response },
    });
  } catch (err) {
    yield put({
      type: GET_ALL_NOTES_FAILURE,
      payload: err,
    });
  }
}

export function* getScreenshotNotes({ payload }) {
  if (!payload) return;

  try {
    let url = `all_notes/${payload}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_SCREENSHOT_NOTES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_SCREENSHOT_NOTES_FAILURE,
      payload: err,
    });
  }
}

export function* addNotes({ payload }) {
  if (!payload) return;
  const { organization_id, project_id, timeframe_id, ...details } = payload;
  try {
    let url = `${organization_id}/projects/${project_id}/timeframes/${timeframe_id}/notes/`;
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: ADD_NOTES_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: ADD_NOTES_FAILURE,
      payload: err,
    });
  }
}

export function* deleteScreenshot({ payload }) {
  if (!payload) return;
  const { organization_id, timesheet_id, screenshot_id, ...details } = payload;
  try {
    let url = `${organization_id}/timesheets/timesheets-manual/${timesheet_id}/delete/`;
    const response = yield defaultApi(url, "POST", details);
    yield put({
      type: DELETE_SCREENSHOT_SUCCESS,
      payload: { data: response, deletedScreenshotId: screenshot_id },
    });
  } catch (err) {
    yield put({
      type: DELETE_SCREENSHOT_FAILURE,
      payload: err,
    });
  }
}

export function* sortScreenshots({ payload }) {
  if (!payload) return;
  try {
    yield put({
      type: SORT_SCREENSHOTS_SUCCESS,
      payload: payload,
    });
  } catch (err) {
    yield put({
      type: SORT_SCREENSHOTS_FAILURE,
      payload: err,
    });
  }
}

export function* getUserActivity({ payload }) {
  if (!payload) return;
  const { organization_id, date, project_id, task_id, user_id, timezone } =
    payload;
  try {
    let url = `${organization_id}/timesheets/timeframes/average-activity/?date=${date}${
      project_id ? `&project_id=${project_id}` : ``
    }${task_id ? `&task_id=${task_id}` : ``}${
      user_id ? `&user_id=${user_id}` : ``
    }${timezone ? `&tz=${timezone}` : ``}`;

    const response = yield defaultApi(url, "GET", payload);
    yield put({
      type: GET_USER_ACTIVITY_SUCCESS,
      payload: { data: response.data },
    });
  } catch (err) {
    yield put({
      type: GET_USER_ACTIVITY_FAILURE,
      payload: err,
    });
  }
}

export function* getVideoLink({ payload }) {
  if (!payload) return;
  try {
    const { organization_id, video_id } = payload;
    const url = `${organization_id}/timesheets/videos/${video_id}/`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: GET_VIDEO_LINK_SUCCESS,
      payload: response,
    });
  } catch (err) {
    yield put({
      type: GET_VIDEO_LINK_FAILURE,
      payload: err,
    });
  }
}

export function* downloadVideo({ payload }) {
  if (!payload) return;
  const { organization_id, date, user_id, video_ids } = payload;
  try {
    const url = `${organization_id}/timesheets/download/download-video/?date=${date}&user_id=${user_id}&video_ids=${video_ids.join(
      ","
    )}`;
    const response = yield defaultApi(url, "GET");
    yield put({
      type: DOWNLOAD_VIDEO_SUCCESS,
      payload: response.data,
    });
    if (response.data.data) {
      const link = document.createElement("a");
      link.href = response.data.data;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (err) {
    yield put({
      type: DOWNLOAD_VIDEO_FAILURE,
      payload: err,
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(GET_APPS_LIST, getAppsList),
    takeLatest(GET_URL_LIST, getUrlList),
    takeLatest(GET_SCREENSHOT_LIST, getScreenshotList),
    takeLatest(GET_LOCATION_LIST, getLocationtList),
    takeLatest(GET_ALL_NOTES, getAllNotes),
    takeLatest(GET_SCREENSHOT_NOTES, getScreenshotNotes),
    takeLatest(DELETE_SCREENSHOT, deleteScreenshot),
    takeLatest(SORT_SCREENSHOTS, sortScreenshots),
    takeLatest(ADD_NOTES, addNotes),
    takeLatest(GET_USER_ACTIVITY, getUserActivity),
    takeLatest(GET_SCREENCAST_LIST, getScreenCastList),
    takeLatest(GET_VIDEO_LINK, getVideoLink),
    takeLatest(DOWNLOAD_VIDEO, downloadVideo),
  ]);
}
